import React from "react";
import "./clubslisting.scss";
import Constant from "../../util/constant";
import SearchBox from "../../components/SearchBox/SearchBox";
import { Scrollbars } from "react-custom-scrollbars";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CustomMenuBar from "../../components/CustomMenuBar/CustomMenuBar";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import CustomModal from "../../components/Modal/Modal";
import WebService from "../../util/webService";
import { withRouter } from "react-router-dom";
import Tooltip from "react-simple-tooltip";
import MainContext from "../../MainContext";
import Nodata from "../../components/Nodata/Nodata";
class ClubsListing extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.onSearch = this.onSearch.bind(this);
    this.state = {
      toast: {
        show: false,
        type: "Sucess",
        msg: "The club has been edited successfully.",
        errorToast: false,
      },
      clubs: [],
      searchText: "",
      NoData: "",
      typingTimeout: 0,
      togglepageVisible: true,
      deleteModalVisible: false,
      // addModalVisible: false,
      oClubId: "",
      clubModalVisible: false,
      currentUrl: "",
      clubLogoError: "",
      clubExistsError: "",
      isStatusModal: false,
      sStatusTitle: "",
      sStatusText: "",
      isChecked: false,
      clubListUpdated: false,
      defaultForm: {},
      isClubDeleted: this.isClubDeleted,
    };
    // const { isClubDeleted } = MainContext;
    // console.log(isClubDeleted,'CLUB DELETED?');
    // if(isClubDeleted){
    //   this.getList();
    // }
  }

  onApply(toggleVisible) {
    this.setState({ togglepageVisible: toggleVisible });
  }
  componentWillMount() {
    this.setState({ currentUrl: this.props.location.pathname });
    this.getList();
  }

  onSubmitClick = async (values) => {
    const { updateButtonLoading } = this.context;
    updateButtonLoading(true);
    var defaultFormData = {};
    var formData = new FormData();
    var apiUrl = Constant.API.CLUB_ADD;
    if (this.state.oClubId !== "") {
      formData.append("iClubId", this.state.oClubId);
      apiUrl = Constant.API.CLUB_EDIT;
    }
    if (values.sClubLogo !== "") {
      formData.append("sClubLogo", values.sClubLogo);
      defaultFormData.sClubLogo = values.sClubLogo;
    }
    formData.append("sClubName", values.sClubName);
    defaultFormData.sClubName = values.sClubName;
    this.setState({ defaultForm: defaultFormData });
    const test = false;
    if (test) {
      return false;
    }
    await WebService.postWebServices(apiUrl, formData, (response) => {
      if (response.status || response.status >= 200 || response.status <= 210) {
        if (response.data.status === true) {
          this.setState({
            clubModalVisible: false,
            clubListUpdated: !this.state.clubListUpdated,
            oClubId: "",
            clubLogoError: "",
            clubExistsError: "",
            defaultForm: {},
            toast: {
              show: true,
              type: "Success",
              msg: response.data.msg,
              errorToast: false,
            },
          });
          this.getList();
        } else {
          this.setState({
            clubExistsError: response.data.msg,
          });
        }
      }
    });
    updateButtonLoading(false);
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };

  onChangeStatus = async (checked, id) => {
    await WebService.postWebServices(
      Constant.API.CLUB_STATUS,
      { iClubId: id, status: checked },
      (response) => {
        if (
          response.status ||
          response.status >= 200 ||
          response.status <= 210
        ) {
          if (response.data.status === true) {
            this.setState({
              oClubId: "",
              deleteModalVisible: false,
              toast: {
                show: true,
                type: "Success",
                msg: response.data.msg,
                errorToast: false,
              },
            });
            this.getList();
          } else {
            this.setState({
              oClubId: "",
              deleteModalVisible: false,
              toast: {
                show: true,
                type: "Failed",
                msg: response.data.msg,
                errorToast: true,
              },
            });
          }
        }
      }
    );
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  onSidebarClubDelete = () => {
    this.getList();
  };
  onDeleteClub = async (id) => {
    this.setState({
      deleteModalVisible: false,
    });
    let param = {
      iClubId: id,
    };
    await WebService.postWebServices(
      Constant.API.CLUB_DELETE,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            this.setState({
              oClubId: "",
              clubListUpdated: !this.state.clubListUpdated,
              toast: {
                show: true,
                type: "Success",
                msg: response.data.msg,
                errorToast: false,
              },
            });
            this.getList();
          } else {
            this.setState({
              toast: {
                show: true,
                type: "Failed",
                msg: response.data.msg,
                errorToast: true,
              },
            });
          }
        } else {
          this.setState({
            toast: {
              show: true,
              type: "Failed",
              msg: response.data.msg,
              errorToast: true,
            },
          });
        }
      }
    );
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };

  getList = async () => {
    const { updateLoading } = this.context;
    updateLoading(true);
    await WebService.postWebServices(
      Constant.API.CLUB_LIST,
      { searchText: this.state.searchText },
      (response) => {
        updateLoading(false);
        console.log("sdsdsd", response);
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data;
          if (response.data.status === true) {
            this.setState({
              clubs: mainData,
            });
          } else {
            this.setState({
              clubs: [],
              NoData: mainData.msg,
            });
          }
        }
      }
    );
  };
  onClear = () => {
    this.setState(
      {
        searchText: "",
      },
      () => {
        this.getList();
      }
    );
  };
  onSearch = (e) => {
    const self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: e.target.value,
      typingTimeout: setTimeout(function () {
        self.getList();
      }, 500),
    });
  };
  render() {
    const {
      toast,
      clubModalVisible,
      deleteModalVisible,
      oClubId,
      isStatusModal,
      sStatusTitle,
      sStatusText,
      isChecked,
      searchText,
      clubListUpdated,
    } = this.state;
    return (
      <div className="clubslistingPage">
        <Header {...this.props} logonotVisible={true} />
        <CustomMenuBar
          refreshClubs={() => {
            this.getList();
          }}
          onToggleClick={(toggleVisible) => this.onApply(toggleVisible)}
          {...this.props}
          clubListUpdated={clubListUpdated}
          onSidebarClubDelete={this.onSidebarClubDelete}
        />
        <div className="centerSec">
          <ToastComponent
            onClose={() => {
              this.setState({
                toast: {
                  show: false,
                  type: this.state.toast.type,
                  msg: this.state.toast.msg,
                  errorToast: this.state.toast.errorToast,
                },
              });
            }}
            show={toast.show}
            toastMessage={toast.msg}
            toastMessageTye={toast.type}
            errorToast={toast.errorToast}
          />
          <div
            className={
              this.context.isDrawerOpen ? "rightMain" : "rightMain sidePadding"
            }
          >
            <div className="searchSec">
              <div className="leftSec">
                <span className="blackdarkTxt">List of Clubs</span>
                <span className="divider"></span>
                <span className="greyTxt">{this.state.clubs.length} Total</span>
                <div className="searchMain">
                  <SearchBox
                    placeholder="Search club name"
                    onChange={this.onSearch}
                    value={searchText}
                    onClear={this.onClear}
                  />
                </div>
              </div>
              <div className="rightBtn">
                <a
                  className="cyanBtn"
                  onClick={() =>
                    this.setState({ clubModalVisible: !clubModalVisible })
                  }
                >
                  <i className="icon-protection clubIcon"></i>
                  ADD CLUB
                </a>
              </div>
            </div>
            <div className="clublisting">
              <div className="listingTopTxtSec">
                <div className="txtSec">
                  <span className="blacTxt">Clubs</span>
                  <span className="greyTxt">
                    Responsible for operations, investments and culture
                  </span>
                </div>
              </div>

              <Scrollbars className="clubsScroll">
                {this.state.clubs.length > 0 && (
                  <ul>
                    {this.state.clubs.map((item) => {
                      return (
                        <li key={item._id}>
                          <div
                            className="card"
                            onClick={() => {
                              this.props.history.push("/club", {
                                iClubId: item._id,
                              });
                            }}
                          >
                            <div className="clubImg">
                              <img
                                src={item.sClubLogo.replace("upload/", "upload/w_167,c_scale/")}
                                className="clubImgmain"
                                alt="Logo"
                              />

                            </div>
                            <div className="bottomSec">
                              <span className="greycardTxt">
                                {item.sClubName}
                              </span>
                              <div
                                className="manageSec"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <a
                                  onClick={() =>
                                    this.setState({
                                      clubModalVisible: !clubModalVisible,
                                      oClubId: item._id,
                                      defaultForm: {
                                        sClubName: item.sClubName,
                                        sClubLogo: item.sClubLogo,
                                      },
                                    })
                                  }
                                >
                                  <Tooltip
                                    content="EDIT"
                                    placement="right"
                                    className="tooltipSmall"
                                  >
                                    <i className="icon-edit_pencil"></i>
                                  </Tooltip>
                                </a>
                                <a
                                  onClick={() =>
                                    this.setState({
                                      deleteModalVisible: !deleteModalVisible,
                                      oClubId: item._id,
                                      isStatusModal: false,
                                    })
                                  }
                                >
                                  <Tooltip
                                    content="DELETE"
                                    placement="right"
                                    className="tooltipSmall"
                                  >
                                    <i className="icon-DeleteNew"></i>
                                  </Tooltip>
                                </a>
                                <span className="toggle">
                                  <Tooltip
                                    content={
                                      !item.nStatus
                                        ? "Show this club on mobile?"
                                        : "Hide this club on mobile?"
                                    }
                                    placement="bottom"
                                    className="tooltipBig"
                                  >
                                    <input
                                      type="checkbox"
                                      checked={item.nStatus}
                                      id={"toggle_" + item._id}
                                      className="checkbox"
                                      // onChange={(e) => { this.onChangeStatus(e.target.checked, item._id) }}
                                      onChange={(e) =>
                                        this.setState({
                                          isStatusModal: true,
                                          deleteModalVisible: !deleteModalVisible,
                                          oClubId: item._id,
                                          isChecked: e.target.checked,
                                          sStatusText: !item.nStatus
                                            ? "Are you sure you want to enable"
                                            : "Are you sure you want to disable",
                                          sStatusTitle: !item.nStatus
                                            ? "Enable"
                                            : "Disable",
                                        })
                                      }
                                    />
                                    <label
                                      htmlFor={"toggle_" + item._id}
                                      className="switch"
                                    ></label>
                                  </Tooltip>
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
                {this.state.clubs.length === 0 && (
                  <Nodata centerTxt="No Data" />
                )}
              </Scrollbars>
            </div>
          </div>
        </div>
        <CustomModal
          _id={oClubId}
          showModal={clubModalVisible}
          clubNamePlaceholder="Club Name"
          sClubLogo={this.state.defaultForm?.sClubLogo}
          defaultForm={this.state.defaultForm}
          onHide={() =>
            this.setState({
              clubModalVisible: !clubModalVisible,
              defaultForm: {},
              oClubId: "",
              clubLogoError: "",
              clubExistsError: "",
            })
          }
          titleName={oClubId === "" ? "Add Club" : "Edit Club"}
          modalType="clubModal"
          clubExistsError={this.state.clubExistsError}
          onSubmit={(details) => {
            this.onSubmitClick(details);
          }}
        />
        <CustomModal
          showModal={deleteModalVisible}
          _id={oClubId}
          onHide={() =>
            this.setState({
              deleteModalVisible: !deleteModalVisible,
              oClubId: "",
            })
          }
          isStatusModal={isStatusModal}
          titleName={!isStatusModal ? "Delete" : sStatusTitle}
          modalType="deleteModal"
          deletItemName="this club?"
          onClick={(id) => {
            !isStatusModal
              ? this.onDeleteClub(id)
              : this.onChangeStatus(isChecked, id);
          }}
          statusText={sStatusText}
          statusTitle={sStatusTitle}
        />
        <Footer
          divClassname={
            this.context.isDrawerOpen ? "simpleFooter" : "footersidePadding"
          }
        />
      </div>
    );
  }
}

export default withRouter(ClubsListing);
