import React from "react";
import "./SettingsList.scss";
import Constant from "../../util/constant";
import SearchBox from "../../components/SearchBox/SearchBox";
import addLinkIcon from "../../assets/images/addLinkIcon.svg";
import { Scrollbars } from "react-custom-scrollbars";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CustomMenuBar from "../../components/CustomMenuBar/CustomMenuBar";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import CustomModal from "../../components/Modal/Modal";
import WebService from "../../util/webService";
import CustomPagination from "../../components/Pagination/Pagination";
import MainContext from "../../MainContext";
import Tooltip from "react-simple-tooltip";
import Nodata from "../../components/Nodata/Nodata";
import { getFormDataObj, showToast, closeToast } from "../../util/helper";
import clubImg from "../../assets/images/no-img.png";
import { CSVLink } from "react-csv";

const PAGENO = 1;
const LIMIT = 10;

class SettingsList extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      toast: {
        show: false,
        type: "Sucess",
        msg: "The club has been edited successfully.",
        errorToast: false,
      },
      users: [],
      searchText: "",
      NoData: "",
      typingTimeout: 0,
      exportList: [],
      togglepageVisible: true,
      HomepageModalVisible: false,
      deleteModalVisible: false,
      selectedColor: "yellow",
      pageLinkList: [],
      selectedId: "",
      isChecked: false,
      selectedObj: { btnColor: "#000000" },
      totalRecords: 0,
      isSortDesc: false,
      pageNo: PAGENO,
      sortBy: "",
      btnColor: "#000000",
      isEdit: false,
    };
  }

  componentDidMount = () => {
    this.getHomepageListingWebService();
  };

  getHomepageListingWebService = async () => {
    let { searchText, pageNo, sortBy } = this.state;
    let param = {
      // sClubIdentifier: "grenta-fc",
      searchText: searchText,
      page: pageNo,
      limit: LIMIT,
      sortBy: sortBy,
    };
    const { updateButtonLoading } = this.context;
    updateButtonLoading(true);
    await WebService.postWebServices(
      Constant.API.GET_HOMEPAGE_LINK,
      param,
      (response) => {
        updateButtonLoading(false);
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            this.setState({
              pageLinkList: response.data.data,
              totalRecords: response.data.totalRecords,
            });
          } else {
            this.setState({ pageLinkList: [] });
          }
        }
      }
    );
    await WebService.getWebServices(
      Constant.API.HOMEPAGE_EXPORT_LIST,
      param,
      (response) => {
        updateButtonLoading(false);
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data.users;
          if (response.data.status === true) {
            var exportData = [];
            mainData.map((d) => {
              exportData.push({
                Name: d.sTitle,
                Link: d.sLink,
                "Button Color": d.sBtnColor,
              });
            });
            this.setState(
              {
                exportList: exportData,
              },
              () => { }
            );
          } else {
            this.setState({
              exportList: [],
              NoData: mainData.msg,
            });
          }
        }
      }
    );
  };
  onApply(toggleVisible) {
    this.setState({ togglepageVisible: toggleVisible });
  }

  onFormSubmit = (values, e) => {
    if (this.state.selectedObj?.id) {
      this.editLinkWebService(values, e, this.state.selectedObj?.id);
    } else {
      this.addLinkWebService(values, e);
    }
  };
  addLinkWebService = async (values) => {
    const { updateButtonLoading } = this.context;
    updateButtonLoading(true);
    let toastMsg;
    let param = {
      sLink: values.url,
      sTitle: values.name,
      sImage: values.sProfileImage,
      sBtnColor: this.state.btnColor,
      sClubIdentifier: "grenta-fc",
      oClubId: values.oClubId.value,
    };
    let formData = getFormDataObj(param);
    await WebService.postWebServices(
      Constant.API.ADD_HOMEPAGE_LINK,
      formData,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          // let mainData = response.data.data;
          if (response.data.status) {
            toastMsg = showToast("Success", response.data.msg);
            this.setState({
              HomepageModalVisible: false,
              toast: toastMsg,
              btnColorL: "#000000",
            });
            this.getHomepageListingWebService();
            updateButtonLoading(false);
          } else {
            toastMsg = showToast("Failed", response.data.msg);
            this.setState({ toast: toastMsg });
          }
        }
      }
    );
    setTimeout(() => {
      this.setState({
        toast: closeToast(
          this.state.toast.type,
          this.state.toast.msg,
          this.state.toast.errorToast
        ),
      });
    }, 5000);
    updateButtonLoading(false);
  };
  editLinkWebService = async (values, e, selectedId) => {
    const { updateButtonLoading } = this.context;
    updateButtonLoading(true);
    let toastMsg;

    let param = {
      iLinkID: selectedId,
      sLink: values.url,
      sTitle: values.name,
      sImage: values.sProfileImage,
      sBtnColor: this.state.btnColor,
      sClubIdentifier: "grenta-fc",
      oClubId: values.oClubId.value
    };
    let formData = getFormDataObj(param);
    await WebService.postWebServices(
      Constant.API.EDIT_HOMEPAGE_LINK,
      formData,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          // let mainData = response.data.data;
          if (response.data.status) {
            toastMsg = showToast("Success", response.data.msg);
            this.setState({
              HomepageModalVisible: false,
              toast: toastMsg,
            });
            this.getHomepageListingWebService();
          } else {
            toastMsg = showToast("Failed", response.data.msg);
            this.setState({ toast: toastMsg });
          }
        }
      }
    );
    setTimeout(() => {
      // this.setState(prevState => ({ toast: !toast.show }));
      // closeToast(this.state.toast.msg, this.state.toast.errorToast)
      this.setState({
        toast: closeToast(
          this.state.toast.type,
          this.state.toast.msg,
          this.state.toast.errorToast
        ),
      });
    }, 5000);
    updateButtonLoading(false);
  };

  onDeleteClick = (selectedId) => {
    this.setState({
      deleteModalVisible: !this.state.deleteModalVisible,
      selectedId,
      isStatusModal: false,
    });
  };
  deleteHomePageLinkWebService = async (selectedId) => {
    const { updateButtonLoading } = this.context;
    updateButtonLoading(true);
    this.setState({
      deleteModalVisible: false,
    });
    let param = { iLinkID: selectedId, sClubIdentifier: "grenta-fc" };
    let toastMsg;
    try {
      await WebService.postWebServices(
        Constant.API.HOMEPAGE_LINK_DELETE,
        param,
        (response) => {
          if (response.status >= 200 || response.status <= 210) {
            if (response.data.status === true) {
              toastMsg = showToast("Success", response.data.msg);
              this.setState({ toast: toastMsg });
              // this.getHomepageListingWebService();
              //If last record of the current page has been deleted, then set the page number to previous page.
              var start = this.state.pageNo * LIMIT - (LIMIT - 1);
              var end = Math.min(start + LIMIT - 1, this.state.totalRecords);
              if (start === end && this.state.pageNo !== 1) {
                this.setState({ pageNo: this.state.pageNo - 1 }, () => {
                  this.getHomepageListingWebService();
                });
              } else {
                this.getHomepageListingWebService();
              }
              updateButtonLoading(false);
            } else {
              toastMsg = showToast("Failed", response.data.msg);
              this.setState({ toast: toastMsg });
            }
          }
        }
      );
    } catch (error) { }
    setTimeout(() => {
      // this.setState(prevState => ({ toast: !toast.show }));
      // closeToast(this.state.toast.msg, this.state.toast.errorToast)
      this.setState({
        toast: closeToast(
          this.state.toast.type,
          this.state.toast.msg,
          this.state.toast.errorToast
        ),
      });
    }, 5000);
    updateButtonLoading(false);
  };

  onSwitchToggle = (event, item) => {
    this.setState({
      isStatusModal: true,
      deleteModalVisible: !this.state.deleteModalVisible,
      selectedId: item._id,
      isChecked: event.target.checked,
      sStatusText: !item.nStatus
        ? "Are you sure you want to enable"
        : "Are you sure you want to disable",
      sStatusTitle: !item.nStatus ? "Enable" : "Disable",
    });
  };
  onChangeStatus = async (checked, id) => {
    let toast;
    await WebService.postWebServices(
      Constant.API.HOMEPAGE_LINK_STATUS,
      { iLinkID: id, status: checked, sClubIdentifier: "grenta-fc" },
      (response) => {
        if (
          response.status ||
          response.status >= 200 ||
          response.status <= 210
        ) {
          if (response.data.status === true) {
            toast = showToast("Success", response.data.msg);
            this.setState({
              oClubId: "",
              deleteModalVisible: false,
              toast,
              // toast: {
              //   show: true,
              //   type: "Success",
              //   msg: response.data.msg,
              //   errorToast: false
              // }
            });
            this.getHomepageListingWebService();
          }
        }
      }
    );
    setTimeout(() => {
      this.setState({
        toast: closeToast(
          this.state.toast.type,
          this.state.toast.msg,
          this.state.toast.errorToast
        ),
      });
    }, 5000);
  };

  onEditClick = (selectedObject) => {
    let selectedObj = {};
    console.log(selectedObject, 'SELECTED OBJECT');
    selectedObj.oClubId = { label: selectedObject.club.sClubName, value: selectedObject.club._id };
    selectedObj.id = selectedObject._id;
    // selectedObj.oClubId = selectedObject.clubId;
    selectedObj.name = selectedObject.sTitle;
    selectedObj.url = selectedObject.sLink;
    selectedObj.sProfileImage = selectedObject.sImage
      ? selectedObject.sImage.replace("upload/", "upload/w_120,c_scale/")
      : "";
    // selectedObj.btnColor = selectedObject.sBtnColor;
    this.setState({
      isEdit: true,
      HomepageModalVisible: !this.state.HomepageModalVisible,
      selectedObj,
    });
  };
  handleSearchChange(e) {
    const searchText = e.target.value;
    this.setState({ searchText }, () => this.getHomepageListingWebService());
  }
  sortingFields = (fieldName) => {
    this.setState({ isSortDesc: !this.state.isSortDesc }, () => {
      this.setState(
        {
          sortBy: this.state.isSortDesc
            ? fieldName + ":desc"
            : fieldName + ":asc",
        },
        () => {
          this.getHomepageListingWebService();
        }
      );
    });
  };
  onPageChange = (nPageNo) => {
    this.setState({ pageNo: nPageNo }, () => {
      this.getHomepageListingWebService();
    });
  };
  onClearClick = () => {
    this.setState({ searchText: "" }, () => {
      this.getHomepageListingWebService();
    });
  };
  onColorChange = (color) => {
    this.setState({ btnColor: color });
  };
  render() {
    const {
      toast,
      HomepageModalVisible,
      isStatusModal,
      isSortDesc,
      totalRecords,
      selectedObj,
      sStatusText,
      sStatusTitle,
      isChecked,
      selectedId,
      deleteModalVisible,
      pageLinkList,
    } = this.state;
    return (
      <div className="HomePageListingPage">
        <Header {...this.props} logonotVisible={true} />
        <CustomMenuBar
          onToggleClick={(toggleVisible) => this.onApply(toggleVisible)}
          {...this.props}
        />
        <div className="centerSec">
          <ToastComponent
            onClose={() => {
              this.setState({
                toast: {
                  show: false,
                  type: this.state.toast.type,
                  msg: this.state.toast.msg,
                  errorToast: this.state.toast.errorToast,
                },
              });
            }}
            show={toast.show}
            toastMessage={toast.msg}
            toastMessageTye={toast.type}
            errorToast={toast.errorToast}
          />
          <div
            className={
              this.context.isDrawerOpen ? "rightMain" : "rightMain sidePadding"
            }
          >
            <div className="searchSec">
              <div className="leftSec">
                <span className="blackdarkTxt">List of Settings Links</span>
                <span className="divider"></span>
                <span className="greyTxt">{totalRecords} Total</span>
                <div className="searchMain">
                  <SearchBox
                    onClear={this.onClearClick}
                    iconVisible={true}
                    name="Searchuser"
                    value={this.state.searchText}
                    onChange={this.handleSearchChange.bind(this)}
                    placeholder="Search"
                  />
                </div>
              </div>
              <div className="rightBtn">
                <a
                  className="cyanBtn"
                  onClick={() =>
                    this.setState({
                      isEdit: false,
                      HomepageModalVisible: !HomepageModalVisible,
                      selectedObj: { btnColor: "#000000" },
                    })
                  }
                >
                  <img src={addLinkIcon} alt="" />
                  ADD LINK
                </a>
              </div>
            </div>
            <div className="homepagelisting">
              <div className="listingTopSec">
                <div className="txtSec">
                  <span className="blacTxt">Settings Links Management</span>
                  <span className="greyTxt">
                    Three-five-two pitch soccer brace center-half halftime
                  </span>
                </div>
                {this.state.exportList.length > 0 && (
                  <div className="btnSec">
                    <CSVLink
                      data={this.state.exportList}
                      filename={"Home Page Link List.csv"}
                      className="yellowExportBtn"
                    // target="_blank"
                    >
                      <i className="icon-DownloadNew clubIcon" />
                      Export
                    </CSVLink>
                  </div>
                )}
              </div>
              <div className="homepageHeader">
                <ul>
                  <li
                    onClick={() => this.sortingFields("sTitle")}
                    className="nameHead"
                  >
                    <a>
                      NAME{" "}
                      <i
                        className={
                          isSortDesc
                            ? "icon-long-arrow downArrow"
                            : "icon-long-arrow upArrow"
                        }
                      />
                    </a>
                  </li>
                  <li className="urlHead">URL</li>
                  <li className="clubHead">Club</li>
                  <li className="actionsHead">ACTIONS</li>
                  <li className="toggleHead">SHOW/HIDE ON MOBILE</li>
                </ul>
              </div>
              <span
                onClick={() => this.sortingFields("sTitle")}
                className="sortDiv"
              >
                Sort by:{" "}
                <a className="linkSmall">
                  Name{" "}
                  <span className="greySmall">
                    {isSortDesc ? "(Z to A)" : "(A to Z)"}
                  </span>
                  <i
                    className={
                      isSortDesc
                        ? "icon-long-arrow downArrow"
                        : "icon-long-arrow upArrow"
                    }
                  />
                </a>
              </span>
              {/* add class "upArrow" together with icon-lon-arrow when u do sorting */}

              <Scrollbars className="homepageScroll">
                {pageLinkList.length !== 0 ? (
                  <ul>
                    {pageLinkList.map((item) => {
                      console.log(item, "ITEM===>");
                      return (
                        <div className="homepageRow" key={item._id}>
                          <li className="nameImgRow">
                            <div className="imgMain">
                              <img
                                src={
                                  item.sImage && item.sImage !== ""
                                    ? item.sImage.replace(
                                      "upload/",
                                      "upload/w_50,c_scale/"
                                    )
                                    : clubImg
                                }
                                className="useIMG"
                                alt=""
                              />
                            </div>
                            <div className="infoSec">
                              <span className="blueTxt">{item.sTitle}</span>
                            </div>
                          </li>
                          <li className="urlRow">
                            <span className="blueTxt">{item.sLink}</span>
                          </li>
                          <li className="clubRow">
                            <span className="blueTxt">{item.club.sClubName}</span>
                          </li>
                          <li className="actionsRow">
                            <a
                              className="clickBtn"
                              onClick={() => this.onEditClick(item)}
                            >
                              <Tooltip
                                content="EDIT"
                                placement="right"
                                className="tooltipSmall"
                              >
                                <i className="icon-edit_pencil" />
                              </Tooltip>
                            </a>
                            <a
                              className="clickBtn"
                              onClick={() => this.onDeleteClick(item._id)}
                            >
                              <Tooltip
                                content="DELETE"
                                placement="right"
                                className="tooltipSmall"
                              >
                                <i className="icon-DeleteNew" />
                              </Tooltip>
                            </a>
                          </li>
                          <li className="toggleRow">
                            <Tooltip
                              content={`${item.nStatus === 1 ? "Show" : "Hide"
                                }${" this link on mobile?"}`}
                              placement="bottom"
                              className="tooltipBig"
                            >
                              <input
                                checked={item.nStatus}
                                type="checkbox"
                                id={"toggle_" + item._id}
                                className="checkbox"
                                onChange={(e) => {
                                  this.onSwitchToggle(e, item);
                                }}
                              />
                              <label
                                htmlFor={"toggle_" + item._id}
                                className="switch"
                              ></label>
                            </Tooltip>
                          </li>
                        </div>
                      );
                    })}
                  </ul>
                ) : (
                    <Nodata centerTxt="No Data" />
                  )}
              </Scrollbars>
              <div className="paginationSec">
                {pageLinkList.length !== 0 && (
                  <CustomPagination
                    activePage={this.state.pageNo}
                    itemsCountPerPage={LIMIT}
                    totalItemsCount={totalRecords}
                    // pageRangeDisplayed={pageRangeDisplayed}
                    handlePageChange={this.onPageChange}
                  />
                )}{" "}
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          // selectedColor={(color) => {
          //   console.log("APPLE PVT", color)
          //   // this.setState({
          //   //   selectedColor: color.hex
          //   // })
          // }}
          isEdit={this.state.isEdit}
          onColorChange={this.onColorChange}
          sProfilePic={selectedObj.sProfileImage}
          titleName={
            this.state.isEdit ? "Edit Settings Link" : "Add New Settings Link"
          }
          defaultForm={selectedObj}
          onFormSubmit={this.onFormSubmit}
          showModal={HomepageModalVisible}
          onHide={() =>
            this.setState({ HomepageModalVisible: !HomepageModalVisible })
          }
          modalType="settingsModal"
        />
        <CustomModal
          showModal={deleteModalVisible}
          onHide={() =>
            this.setState({
              deleteModalVisible: !deleteModalVisible,
              selectedId: "",
            })
          }
          isStatusModal={isStatusModal}
          titleName={
            !isStatusModal ? Constant.MODAL_TITLE.DELETE : sStatusTitle
          }
          modalType={Constant.MODAL_TYPE.DELETE}
          deletItemName="this link?"
          onClick={(id) => {
            !isStatusModal
              ? this.deleteHomePageLinkWebService(id)
              : this.onChangeStatus(isChecked, id);
          }}
          statusText={sStatusText}
          statusTitle={sStatusTitle}
          _id={selectedId}
        />
        <Footer
          divClassname={
            this.context.isDrawerOpen ? "simpleFooter" : "footersidePadding"
          }
        />
      </div>
    );
  }
}

export default SettingsList;
