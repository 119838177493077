export default function getUser() {
  return JSON.parse(localStorage.getItem("KeyUser"));
}

export const getCookie = (cookieName) => {
  let value = [];
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      value = c.substring(name.length, c.length);
    }
  }
  console.log("cookieName " + cookieName + " === value =" + value);
  return value;
};
export function isValidValue(value) {
  if (typeof (value) === "undefined" || value === null || value === "") {
    return false
  }
  return true
}

export function hasProperty(object, key) {
  if (Array.isArray(object) || typeof (key) != 'string' || !object) {
    return false
  } else {
    return object.hasOwnProperty(key)
  }
}

export function getValueFromObject(object, key) {
  if (hasProperty(object, key)) {
    if (isValidValue(object[key])) {
      return object[key]
    }
  }
  return ''
}
export function getFormDataObj(obj) {
  let formData = new FormData()
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      let element = obj[i];
      formData.append(i, element)
    }
  }
  return formData
}
export function showToast(toastType, toastMsg) {
  let toast = {}
  toast.show = true
  toast.type = toastType
  toast.msg = toastMsg
  toast.errorToast = toastType === 'Success' ? false : true
  return toast
}
export function closeToast(toastType, toastMsg, errorFlag) {
  let toast = {}
  toast.show = false
  toast.type = toastType
  toast.msg = toastMsg
  toast.errorToast = errorFlag
  return toast
}