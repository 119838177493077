import React from "react";
import "./fixturestableslisting.scss";
import Constant from "../../util/constant";
import SearchBox from "../../components/SearchBox/SearchBox";
import { Scrollbars } from "react-custom-scrollbars";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CustomMenuBar from "../../components/CustomMenuBar/CustomMenuBar";
import CustomButton from "../../components/CustomButton/button";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import CustomModal from "../../components/Modal/Modal";
import WebService from "../../util/webService";
import Tooltip from "react-simple-tooltip";
import MainContext from "../../MainContext";
import Nodata from "../../components/Nodata/Nodata";

class FixturesTablesListing extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.onSearch = this.onSearch.bind(this);
    this.state = {
      toast: {
        show: false,
        type: "Sucess",
        msg: "The club has been edited successfully.",
        errorToast: false,
      },
      items: [],
      searchText: "",
      NoData: "",
      typingTimeout: 0,
      togglepageVisible: true,
      deleteModalVisible: false,
      addModalVisible: false,
      sClubName: this.props.location.state?.sClubName,
      sClubLogo: this.props.location.state?.sClubLogo,
      totalUsers: this.props.location.state?.totalUsers,
      since: this.props.location.state?.since,
      oFixturesId: "",
      sClubIdentifier: this.props.location.state?.sClubIdentifier,
      defaultForm: {},
      clubModalVisible: false,
      currentUrl: "",
      isStatusModal: false,
      isChecked: false,
      sStatusTitle: "",
      sStatusText: "",
    };
  }

  onApply(toggleVisible) {
    this.setState({ togglepageVisible: toggleVisible });
  }

  componentWillMount() {
    this.setState({ currentUrl: this.props.location.pathname });
    this.getList();
  }

  onSubmitClick = async (values) => {
    const { updateButtonLoading } = this.context;
    updateButtonLoading(true);
    var formData = new FormData();
    this.setState({ defaultForm: values });
    var apiUrl = Constant.API.FIXTURES_ADD;
    if (this.state.oFixturesId !== "") {
      formData.append("iFixtureId", this.state.oFixturesId);
      apiUrl = Constant.API.FIXTURES_EDIT;
    }
    if (values.sFixturesLogo !== "") {
      formData.append("sFixturesLogo", values.sFixturesLogo);
    }
    formData.append("sFixturesTitle", values.sFixturesTitle);
    formData.append("sFixturesURL", values.sFixturesURL);
    formData.append("sClubIdentifier", this.state.sClubIdentifier);

    const test = false;
    if (test) {
      return false;
    }
    await WebService.postWebServices(apiUrl, formData, (response) => {
      if (response.status || response.status >= 200 || response.status <= 210) {
        if (response.data.status === true) {
          // this.setState({ addModalVisible: false });
          this.setState({
            clubModalVisible: false,
            oFixturesId: "",
            defaultForm: {},
            sEditFixturesURL: "",
            toast: {
              show: true,
              type: "Success",
              msg: response.data.msg,
              errorToast: false,
            },
          });
          this.getList();
        }
      }
    });
    updateButtonLoading(false);
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };

  onChangeStatus = async (checked, id) => {
    await WebService.postWebServices(
      Constant.API.FIXTURES_STATUS,
      {
        iFixtureId: id,
        status: checked,
        sClubIdentifier: this.state.sClubIdentifier,
      },
      (response) => {
        if (
          response.status ||
          response.status >= 200 ||
          response.status <= 210
        ) {
          if (response.data.status === true) {
            this.setState({
              oFixturesId: "",
              deleteModalVisible: false,
              toast: {
                show: true,
                type: "Success",
                msg: response.data.msg,
                errorToast: false,
              },
            });
            this.getList();
          }
        }
      }
    );
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  onDelete = async (id) => {
    this.setState({
      deleteModalVisible: false,
    });
    let param = {
      iFixtureId: id,
      sClubIdentifier: this.state.sClubIdentifier,
    };
    await WebService.postWebServices(
      Constant.API.FIXTURES_DELETE,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            this.setState({
              oFixturesId: "",
              toast: {
                show: true,
                type: "Success",
                msg: response.data.msg,
                errorToast: false,
              },
            });
            this.getList();
          }
        } else {
        }
      }
    );
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  getList = async () => {
    await WebService.postWebServices(
      Constant.API.FIXTURES_LIST,
      // { searchText: this.state.searchText },
      {
        searchText: this.state.searchText,
        sClubIdentifier: this.props.location.state.sClubIdentifier,
      },
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data;
          if (response.data.status === true) {
            this.setState({
              items: mainData,
            });
          } else {
            this.setState({
              items: [],
              NoData: mainData.msg,
            });
          }
        }
      }
    );
  };
  onClear = () => {
    this.setState(
      {
        searchText: "",
      },
      () => {
        this.getList();
      }
    );
  };
  onSearch = (e) => {
    const self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: e.target.value,
      typingTimeout: setTimeout(function () {
        self.getList();
      }, 500),
    });
  };
  render() {
    const {
      toast,
      clubModalVisible,
      deleteModalVisible,
      oFixturesId,
      sClubName,
      totalUsers,
      since,
      defaultForm,
      sEditFixturesLogo,
      sClubLogo,
      isStatusModal,
      isChecked,
      sStatusTitle,
      sStatusText,
      searchText,
    } = this.state;
    return (
      <div className="FixturesTablesListingPage">
        <Header {...this.props} logonotVisible={true} />
        <CustomMenuBar
          onToggleClick={(toggleVisible) => this.onApply(toggleVisible)}
          {...this.props}
        />
        <div className="centerSec">
          <ToastComponent
            onClose={() => {
              this.setState({
                toast: {
                  show: false,
                  type: this.state.toast.type,
                  msg: this.state.toast.msg,
                  errorToast: this.state.toast.errorToast,
                },
              });
            }}
            show={toast.show}
            toastMessage={toast.msg}
            toastMessageTye={toast.type}
            errorToast={toast.errorToast}
          />
          <div
            className={
              this.context.isDrawerOpen ? "rightMain" : "rightMain sidePadding"
            }
          >
            <div className="searchSec">
              <a
                className="backICON"
                onClick={() => {
                  this.props.history.push("/club", {
                    iClubId: this.props.location.state.iClubId,
                  });
                }}
              >
                <i className="icon-left-arrow" />
                <span className="blackdarkTxt">
                  Fixtures & Tables League Associations
                </span>
              </a>
              <span className="divider"></span>

              <div className="searchMain">
                <SearchBox
                  placeholder="Search fixture name"
                  onChange={this.onSearch}
                  value={searchText}
                  onClear={this.onClear}
                />
              </div>
            </div>
            <Scrollbars className="fixturestableslisting">
              <div className="fixturesScroll">
                <div className="listingTopImgSec">
                  <div className="backImgSec">
                    <div className="imgCircle">
                      <div className="imgDiv">
                        <img src={sClubLogo} className="clublogoMain" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="blacTxtSec">
                    <span className="blacTxt">{sClubName}</span>
                  </div>
                  <div className="greyTxtSec">
                    <span className="greyTxt">
                      {totalUsers}{" "}
                      {parseInt(totalUsers) === 1 ? "User" : "Users"}
                    </span>
                  </div>
                  <div className="greyTxtSec">
                    <span className="greyTxt">Since {since}</span>
                  </div>
                </div>

                <div className="txtBtnSec">
                  {/* <span className="greyBtnSecTxt">Hat trick goalie red card yellow card center-half chip</span> */}
                  <CustomButton
                    type="submit"
                    title="ADD LINK"
                    onClick={() =>
                      this.setState({ clubModalVisible: !clubModalVisible })
                    }
                  >
                    <i className="icon-linkAdd clubIcon"></i>
                  </CustomButton>
                </div>
                {this.state.items.length > 0 && (
                  <div className="fixtureCardSec">
                    <ul>
                      <li>
                        {this.state.items.map((item) => {
                          var url = item.sFixturesURL;
                          if (
                            url.indexOf("http://") !== -1 ||
                            url.indexOf("https://") !== -1
                          ) {
                            if (!url.match(/www\./g)) {
                              url = `${item.sFixturesURL.split("/")[0]}//www.`;
                              item.sFixturesURL
                                .split("/")
                                .forEach((element, index) => {
                                  if (index > 0) {
                                    url = `${url}${
                                      item.sFixturesURL.split("/")[index]
                                    }`;
                                    if (index > 1) {
                                      url = `${url}/`;
                                    }
                                  }
                                });
                            }
                          } else {
                            if (!url.match(/www\./g)) {
                              url = `www.${item.sFixturesURL}`;
                            }
                          }
                          return (
                            <div className="fixtureCard">
                              <div className="fixturesImg">
                                <img
                                  src={
                                    item.sFixturesLogo &&
                                    item.sFixturesLogo !== ""
                                      ? item.sFixturesLogo.replace("upload/","upload/w_191,c_scale/")
                                      : this.state.sClubLogo.replace("upload/","upload/w_191,c_scale/")
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="bottomSec">
                                <div className="txtCardSec">
                                  <span className="blueBigTxt">
                                    {item.sFixturesTitle}
                                  </span>
                                  <span className="greycardSubTxt">
                                    {url}
                                    {/* {!item.sFixturesURL.match(/www\./g) ? 
                                    (item.sFixturesURL.indexOf("http://") !== 0 || item.sFixturesURL.indexOf("https://") !== 0 
                                    ? `${item.sFixturesURL.split('/')[0]}//wwww.${item.sFixturesURL.replace(item.sFixturesURL.split('/')[1])}` 
                                    :`www.${item.sFixturesURL}`) : item.sFixturesURL} */}
                                  </span>
                                </div>
                                <div
                                  className="manageSec"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <a
                                    onClick={() =>
                                      this.setState({
                                        clubModalVisible: !clubModalVisible,
                                        oFixturesId: item._id,
                                        defaultForm: {
                                          sFixturesTitle: item.sFixturesTitle,
                                          sFixturesLogo: item.sFixturesLogo.replace("upload/","upload/w_191,c_scale/"),
                                          sFixturesURL: item.sFixturesURL,
                                        },
                                      })
                                    }
                                  >
                                    <Tooltip
                                      content="EDIT"
                                      placement="right"
                                      className="tooltipSmall"
                                    >
                                      <i className="icon-edit_pencil"></i>
                                    </Tooltip>
                                  </a>
                                  <a
                                    onClick={() =>
                                      this.setState({
                                        deleteModalVisible: !deleteModalVisible,
                                        isStatusModal: false,
                                        oFixturesId: item._id,
                                      })
                                    }
                                  >
                                    <Tooltip
                                      content="DELETE"
                                      placement="right"
                                      className="tooltipSmall"
                                    >
                                      <i className="icon-DeleteNew"></i>
                                    </Tooltip>
                                  </a>
                                  <span className="toggle">
                                    <Tooltip
                                      content="Show this league & fixture on mobile?"
                                      placement="bottom"
                                      className="tooltipBig"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={item.nStatus}
                                        id={"toggle_" + item._id}
                                        className="checkbox"
                                        // onChange={(e) => { this.onChangeStatus(e.target.checked, item._id) }}
                                        onChange={(e) =>
                                          this.setState({
                                            isStatusModal: true,
                                            deleteModalVisible: !deleteModalVisible,
                                            oFixturesId: item._id,
                                            isChecked: e.target.checked,
                                            sStatusText: !item.nStatus
                                              ? "Are you sure you want to enable"
                                              : "Are you sure you want to disable",
                                            sStatusTitle: !item.nStatus
                                              ? "Enable"
                                              : "Disable",
                                          })
                                        }
                                      />
                                      <label
                                        htmlFor={"toggle_" + item._id}
                                        className="switch"
                                      ></label>
                                    </Tooltip>
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </li>
                    </ul>
                  </div>
                )}
                {this.state.items.length === 0 && (
                  <Nodata centerTxt="No Data" />
                )}
              </div>
            </Scrollbars>
          </div>
        </div>
        <CustomModal
          showModal={clubModalVisible}
          defaultForm={defaultForm}
          sFixturesLogo={defaultForm?.sFixturesLogo}
          leagueNamePlaceholder="Enter League Title"
          leagueUrlPlaceholder="Enter League URL"
          onHide={() =>
            this.setState({
              clubModalVisible: !clubModalVisible,
              defaultForm: {},
              oFixturesId: "",
            })
          }
          titleName={
            oFixturesId === "" ? "Add Fixture & Table" : "Edit Fixture & Table"
          }
          modalType="clubModal"
          leagueModal={true}
          onSubmit={(details) => {
            this.onSubmitClick(details);
          }}
          _id={oFixturesId}
        />

        <CustomModal
          showModal={deleteModalVisible}
          _id={oFixturesId}
          isStatusModal={isStatusModal}
          onHide={() =>
            this.setState({
              deleteModalVisible: !deleteModalVisible,
              oFixturesId: "",
            })
          }
          onClick={(id) => {
            !isStatusModal
              ? this.onDelete(id)
              : this.onChangeStatus(isChecked, id);
          }}
          titleName={!isStatusModal ? "Delete" : sStatusTitle}
          modalType="deleteModal"
          deletItemName="this fixture?"
          statusText={sStatusText}
          statusTitle={sStatusTitle}
        />
        <Footer
          divClassname={
            this.context.isDrawerOpen ? "simpleFooter" : "footersidePadding"
          }
        />
      </div>
    );
  }
}

export default FixturesTablesListing;
