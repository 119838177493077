import React from "react";
import "./clubsponserslisting.scss";
import Constant from "../../util/constant";
import SearchBox from "../../components/SearchBox/SearchBox";
import { Scrollbars } from "react-custom-scrollbars";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CustomMenuBar from "../../components/CustomMenuBar/CustomMenuBar";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import CustomModal from "../../components/Modal/Modal";
import WebService from "../../util/webService";
import CustomPagination from "../../components/Pagination/Pagination";
import MainContext from "../../MainContext";
import Tooltip from "react-simple-tooltip";
import Nodata from "../../components/Nodata/Nodata";
import { CSVLink } from "react-csv";
class ClubSponsersListing extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      toast: {
        show: false,
        type: "Sucess",
        msg: "The club has been edited successfully.",
        errorToast: false,
      },
      users: [],
      searchText: "",
      NoData: "",
      typingTimeout: 0,
      togglepageVisible: true,
      sponsersModalVisible: false,
      deleteModalVisible: false,
      sClubIdentifier: this.props.location.state.sClubIdentifier,
      activePage: 1,
      itemsCountPerPage: 5,
      totalItemsCount: 0,
      pageRangeDisplayed: 7,
      nPageNo: 1,
      isLoading: false,
      sortUpper: false,
      sortBy: { sSponsorTitle: 1 },
      start: 0,
      end: 0,
      oSponsorId: "",
      isChecked: false,
      sStatusTitle: "",
      sStatusText: "",
      editDetail: {},
      exportList: [],
      sClubName: "",
      sponsorExistsError: "",
      deletedImages: [],
    };
  }
  onDeleteUser = async (id) => {
    const { updateLoading } = this.context;
    updateLoading(true);
    this.setState({
      deleteModalVisible: false,
    });
    let param = {
      iSponsorId: id,
      sClubIdentifier: this.state.sClubIdentifier,
    };
    await WebService.postWebServices(
      Constant.API.SPONSORS_DELETE,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            this.setState({ oUserId: "" });
            this.setState({
              toast: {
                show: true,
                type: "Success",
                msg: response.data.msg,
                errorToast: false,
              },
            });
            //If last record of the current page has been deleted, then set the page number to previous page.
            var start =
              this.state.activePage * this.state.itemsCountPerPage -
              (this.state.itemsCountPerPage - 1);
            var end = Math.min(
              start + this.state.itemsCountPerPage - 1,
              this.state.totalItemsCount
            );
            if (start === end && this.state.activePage !== 1) {
              this.setState({ nPageNo: this.state.activePage - 1 }, () => {
                this.getList();
              });
            } else {
              this.getList();
            }
          }
        } else {
          this.setState({
            toast: {
              show: true,
              type: "Failed",
              msg: response.data.msg,
              errorToast: true,
            },
          });
        }
      }
    );
    updateLoading(false);
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  onPageChange = (nPageNo) => {
    this.setState({ nPageNo: nPageNo }, () => {
      this.getList();
    });
  };
  onApply(toggleVisible) {
    this.setState({ togglepageVisible: toggleVisible });
  }
  onSubmitClick = async (values, notificationData) => {
   
    const { updateButtonLoading } = this.context;
    updateButtonLoading(true);
    var formData = new FormData();
    var apiUrl = Constant.API.SPONSORS_ADD;
    if (this.state.oSponsorId !== "") {
      formData.append("iSponsorId", this.state.oSponsorId);
      apiUrl = Constant.API.SPONSORS_EDIT;
    }
    formData.append("sClubIdentifier", this.state.sClubIdentifier);
    formData.append("sSponsorName", values.sSponsorName);
    formData.append("sSponsorEmail", values.sSponsorEmail);
    formData.append("sSponsorTitle", values.sSponsorTitle);
    formData.append("sFacebookUrl", values.facebook);
    formData.append("sTwitterUrl", values.twitter);
    formData.append("aLinks", JSON.stringify(values.links));
    formData.append("sCountryCode", values.sCountryCode);
    formData.append("sSponsorMobile", values.sSponsorMobile);
    formData.append("isSendNotification", notificationData);

    var deletedImages = this.state.deletedImages;
    formData.append("aDeletedImages", deletedImages);
    this.setState({ deletedImages: [] });
    if (typeof values.fSponsorBannerMAX !== "undefined") {
      formData.append("fSponsorBannerMAX", values.fSponsorBannerMAX);
    }
    if (typeof values.fSponsorBannerX !== "undefined") {
      formData.append("fSponsorBannerX", values.fSponsorBannerX);
    }
    if (typeof values.fSponsorLogoLargeMAX !== "undefined") {
      formData.append("fSponsorLogoLargeMAX", values.fSponsorLogoLargeMAX);
    }
    if (typeof values.fSponsorLogoLargeX !== "undefined") {
      formData.append("fSponsorLogoLargeX", values.fSponsorLogoLargeX);
    }
    if (typeof values.fSponsorLogoMediumMAX !== "undefined") {
      formData.append("fSponsorLogoMediumMAX", values.fSponsorLogoMediumMAX);
    }
    if (typeof values.fSponsorLogoMediumX !== "undefined") {
      formData.append("fSponsorLogoMediumX", values.fSponsorLogoMediumX);
    }
    if (typeof values.fSponsorLogoSmallMAX !== "undefined") {
      formData.append("fSponsorLogoSmallMAX", values.fSponsorLogoSmallMAX);
    }
    if (typeof values.fSponsorLogoSmallX !== "undefined") {
      formData.append("fSponsorLogoSmallX", values.fSponsorLogoSmallX);
    }
    await WebService.postWebServices(apiUrl, formData, (response) => {
      if (response.status || response.status >= 200 || response.status <= 210) {
        if (response.data.status === true) {
          this.setState({
            sponsersModalVisible: false,
            sponsorExistsError: "",
            editDetail: {},
            toast: {
              show: true,
              type: "Success",
              msg: response.data.msg,
              errorToast: false,
            },
          });
          this.getList();
        } else {
          this.setState({
            sponsorExistsError: response.data.msg,
          });
        }
      }
    });
    updateButtonLoading(false);
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  getList = async () => {
    const { updateLoading } = this.context;
    // updateLoading(true);
    let param = {
      userRoleId: "UR002",
      nPageNo: this.state.nPageNo,
      nResultsPerPage: this.state.itemsCountPerPage,
      searchText: this.state.searchText,
      sClubIdentifier: this.state.sClubIdentifier,
      sSortBy:
        typeof this.state.sortBy === "undefined"
          ? "undefined"
          : JSON.stringify({
            Key: Object.keys(this.state.sortBy)[0],
            Value: this.state.sortBy[Object.keys(this.state.sortBy)[0]],
          }),
    };
    await WebService.getWebServices(
      Constant.API.SPONSORS_LIST,
      param,
      (response) => {
        updateLoading(false);
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data.users;
          let totalPages = response.data.data.totalPages;
          if (response.data.status === true) {
            var start =
              this.state.nPageNo * this.state.itemsCountPerPage -
              (this.state.itemsCountPerPage - 1);
            mainData = mainData.map((item) => {
              item.id = start;
              start++;
              return item;
            });
            this.setState(
              {
                users: mainData,
                totalItemsCount: response.data.data.totalUsers,
                activePage: this.state.nPageNo,
                pageRangeDisplayed:
                  totalPages < this.state.pageRangeDisplayed
                    ? totalPages
                    : this.state.pageRangeDisplayed,
                totalPages: totalPages,
              },
              () => { }
            );
          } else {
            this.setState({
              users: [],
              NoData: mainData.msg,
            });
            this.setState({
              toast: {
                show: true,
                type: "Failed",
                msg: response.data.msg,
                errorToast: true,
              },
            });
          }
        }
      }
    );
    await WebService.getWebServices(
      Constant.API.SPONSORS_EXPORT_LIST,
      param,
      (response) => {
        updateLoading(false);
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data;
          if (response.data.status === true) {
            var exportData = [];
            var sClubName = mainData[0].Club.sClubName;
            mainData.map((d) => {
              exportData.push({
                Club: d.Club.sClubName,
                Advertorial: d.sSponsorName,
                "Sponsor Name": d.sSponsorTitle,
                "Email ID": d.sSponsorEmail,
                "Phone Number": `+${d.sCountryCode} ${d.sSponsorMobile}`,
                "Facebook URL": `https://www.facebook.com/${d.sFacebookUrl.replace(
                  /\//g,
                  ""
                )}`,
                "Twitter URL": `https://twitter.com/${d.sTwitterUrl.replace(
                  /\//g,
                  ""
                )}`,
                "Banner Max": d.oBanner.max,
                "Banner X": d.oBanner.x,
                "Logo Max": d.oLogoMedium.max,
                "Logo X": d.oLogoMedium.x,
              });
            });
            this.setState({
              exportList: exportData,
              sClubName: sClubName,
            });
          } else {
            this.setState({
              exportList: [],
              NoData: mainData.msg,
            });
          }
        }
      }
    );
  };
  onClear = () => {
    this.setState(
      {
        searchText: "",
      },
      () => {
        setTimeout(() => {
          this.getList();
        }, 500);
      }
    );
  };
  onSearch = (e) => {
    const self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: e.target.value,
      typingTimeout: setTimeout(function () {
        self.getList();
      }, 500),
    });
  };
  onDeleteImage = (imageType) => {
    var deletedImages = this.state.deletedImages;
    deletedImages.push(imageType);
    this.setState({ deletedImages: deletedImages }, () => {
      var editDetail = this.state.editDetail;

      if (this.state.deletedImages.includes("small-max")) {
        editDetail.fSponsorLogoSmallMAX = "";
      }
      if (this.state.deletedImages.includes("small-x")) {
        editDetail.fSponsorLogoSmallX = "";
      }
      if (this.state.deletedImages.includes("large-max")) {
        editDetail.fSponsorLogoLargeMAX = "";
      }
      if (this.state.deletedImages.includes("large-x")) {
        editDetail.fSponsorLogoLargeX = "";
      }
      this.setState({ editDetail: editDetail }, () => {
        console.log(editDetail, "EDIT DETAIL");
      });
    });
  };
  onChangeStatus = async (checked, id) => {
    await WebService.postWebServices(
      Constant.API.SPONSORS_STATUS,
      {
        iSponsorId: id,
        status: checked,
        sClubIdentifier: this.state.sClubIdentifier,
      },
      (response) => {
        if (
          response.status ||
          response.status >= 200 ||
          response.status <= 210
        ) {
          if (response.data.status === true) {
            this.setState({
              oSponsorId: "",
              deleteModalVisible: false,
              toast: {
                show: true,
                type: "Success",
                msg: response.data.msg,
                errorToast: false,
              },
            });
            this.getList();
          }
        }
      }
    );
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  componentWillMount() {
    this.getList();
  }
  render() {
    const {
      toast,
      sponsersModalVisible,
      deleteModalVisible,
      users,
      searchText,
      sortBy,
      totalItemsCount,
      pageRangeDisplayed,
      oSponsorId,
      isChecked,
      isStatusModal,
      sStatusTitle,
      sStatusText,
      editDetail,
      sClubName,
    } = this.state;
    return (
      <div className="ClubSponsersListingPage">
        <Header {...this.props} logonotVisible={true} />
        <CustomMenuBar
          onToggleClick={(toggleVisible) => this.onApply(toggleVisible)}
          {...this.props}
        />
        <div className="centerSec">
          <ToastComponent
            onClose={() => {
              this.setState({
                toast: {
                  show: false,
                  type: this.state.toast.type,
                  msg: this.state.toast.msg,
                  errorToast: this.state.toast.errorToast,
                },
              });
            }}
            show={toast.show}
            toastMessage={toast.msg}
            toastMessageTye={toast.type}
            errorToast={toast.errorToast}
          />
          <div
            className={
              this.context.isDrawerOpen ? "rightMain" : "rightMain sidePadding"
            }
          >
            <div className="searchSec">
              <div className="leftSec">
                <a
                  className="backICON"
                  onClick={() => {
                    this.props.history.push("/club", {
                      iClubId: this.props.location.state.iClubId,
                    });
                  }}
                >
                  <i className="icon-left-arrow" />
                  <span className="blackdarkTxt">List of Club Sponsors</span>
                </a>
                <span className="divider"></span>
                <span className="greyTxt">{users.length} Total</span>
                <div className="searchMain">
                  <SearchBox
                    placeholder="Search name/email/title"
                    onChange={this.onSearch}
                    value={searchText}
                    onClear={this.onClear}
                  />
                </div>
              </div>
              <div className="rightBtn">
                <a
                  className="cyanBtn"
                  onClick={() =>
                    this.setState({
                      oSponsorId: "",
                      sponsersModalVisible: !sponsersModalVisible,
                      sponsorExistsError: "",
                    })
                  }
                >
                  <i className="icon-sponsers" />
                  ADD CLUB SPONSOR
                </a>
              </div>
            </div>
            <div className="clubsponserslisting">
              <div className="listingTopSec">
                <div className="txtSec">
                  <span className="blacTxt">Club Sponsor Management</span>
                  <span className="greyTxt">
                    Forward goalie four-four-two yellow card defender{" "}
                  </span>
                </div>
                {this.state.exportList.length > 0 && (
                  <div className="btnSec">
                    <CSVLink
                      data={this.state.exportList}
                      filename={`${sClubName} - Sponsors List.csv`}
                      className="yellowExportBtn"
                    >
                      <i className="icon-DownloadNew clubIcon" />
                      Export
                    </CSVLink>
                  </div>
                )}
              </div>
              <div className="clubsponsersHeader">
                <ul>
                  <li className="yellowIcons"># </li>
                  <li className="titleHead">
                    <a
                      onClick={() => {
                        if (totalItemsCount > 0) {
                          if (
                            sortBy &&
                            sortBy.sSponsorTitle &&
                            sortBy.sSponsorTitle === -1
                          ) {
                            this.setState(
                              {
                                sortBy: { sSponsorTitle: 1 },
                              },
                              () => {
                                this.getList();
                              }
                            );
                          } else {
                            this.setState(
                              {
                                sortBy: { sSponsorTitle: -1 },
                              },
                              () => {
                                this.getList();
                              }
                            );
                          }
                        }
                      }}
                    >
                      SPONSOR NAME
                      {totalItemsCount > 0 && (
                        <i
                          className={
                            sortBy["sSponsorTitle"] === 1
                              ? "icon-long-arrow upArrow"
                              : "icon-long-arrow downArrow"
                          }
                        />
                      )}
                    </a>
                  </li>
                  <li className="sponsorHead">ADVERTORIAL</li>
                  <li className="phoneHead">PHONE NUMBER</li>
                  <li className="actionsHead">ACTIONS</li>
                  <li className="toggleHead">SHOW/HIDE ON MOBILE</li>
                </ul>
              </div>
              {totalItemsCount > 0 && (
                <span className="sortDiv">
                  Sort by:{" "}
                  <a
                    className="linkSmall"
                    onClick={() => {
                      if (
                        sortBy &&
                        sortBy.sSponsorTitle &&
                        sortBy.sSponsorTitle === -1
                      ) {
                        this.setState(
                          {
                            sortBy: { sSponsorTitle: 1 },
                          },
                          () => {
                            this.getList();
                          }
                        );
                      } else {
                        this.setState(
                          {
                            sortBy: { sSponsorTitle: -1 },
                          },
                          () => {
                            this.getList();
                          }
                        );
                      }
                    }}
                  >
                    Title{" "}
                    <>
                      <span className="greySmall">
                        {sortBy["sTitle"] === 1 ? "(A to Z)" : "(Z to A)"}
                      </span>
                      <i
                        className={
                          sortBy["sSponsorTitle"] === 1
                            ? "icon-long-arrow upArrow"
                            : "icon-long-arrow downArrow"
                        }
                      />
                    </>
                  </a>
                </span>
              )}
              <Scrollbars className="clubsponsersScroll">
                <ul>
                  {users.length > 0 &&
                    users.map((item, index) => {
                      return (
                        <div className="clubsponsersRow" key={item._id}>
                          <li className="noRow">{item.id}</li>
                          <li className="clubsponsersImgRow">
                            <div className="imgMain">
                              <img
                                src={item.oLogoMedium.max.replace("upload/","upload/w_50,c_scale/")}
                                className="useIMG"
                                alt="Logo"
                              />
                            </div>
                            <div className="infoSec">
                              <span className="blueTxt">
                                {item.sSponsorName}
                              </span>
                              <span className="mailTxt">
                                {item.sSponsorEmail}
                              </span>
                            </div>
                          </li>
                          <li className="sponsorRow">
                            <span className="blueTxt">{item.sSponsorTitle}</span>
                          </li>
                          <li className="mobRow">
                            <span className="blueTxt">
                              {item.sSponsorMobile}
                            </span>
                          </li>
                          <li className="actionsRow">
                            <a
                              className="clickBtn"
                              onClick={() =>
                                this.setState({
                                  sponsersModalVisible: !sponsersModalVisible,
                                  oSponsorId: item._id,
                                  editDetail: {
                                    oSponsorId: item._id,
                                    aLinks: item.aLinks,
                                    fSponsorLogoSmallMAX: item.oLogoSmall.max.replace("upload/","upload/w_200,c_scale/"),
                                    fSponsorLogoSmallX: item.oLogoSmall.x.replace("upload/","upload/w_180,c_scale/"),
                                    fSponsorLogoMediumMAX: item.oLogoMedium.max.replace("upload/","upload/w_200,c_scale/"),
                                    fSponsorLogoMediumX: item.oLogoMedium.x.replace("upload/","upload/w_190,c_scale/"),
                                    fSponsorLogoLargeMAX: item.oLogoLarge.max.replace("upload/","upload/w_200,c_scale/"),
                                    fSponsorLogoLargeX: item.oLogoLarge.x.replace("upload/","upload/w_190,c_scale/"),
                                    fSponsorBannerMAX: item.oBanner.max.replace("upload/","upload/w_431,c_scale/"),
                                    fSponsorBannerX: item.oBanner.x.replace("upload/","upload/w_431,c_scale/"),
                                    sSponsorTitle: item.sSponsorTitle,
                                    sSponsorName: item.sSponsorName,
                                    sSponsorEmail: item.sSponsorEmail,
                                    sCountryCode: item.sCountryCode,
                                    sSponsorMobile: item.sSponsorMobile,
                                    facebook: item.sFacebookUrl,
                                    twitter: item.sTwitterUrl,
                                  },
                                })
                              }
                            >
                              <Tooltip
                                content="EDIT"
                                placement="right"
                                className="tooltipSmall"
                              >
                                <i className="icon-edit_pencil" />
                              </Tooltip>
                            </a>
                            <a
                              className="clickBtn"
                              onClick={() =>
                                this.setState({
                                  deleteModalVisible: !deleteModalVisible,
                                  oSponsorId: item._id,
                                  isStatusModal: false,
                                })
                              }
                            >
                              <Tooltip
                                content="DELETE"
                                placement="right"
                                className="tooltipSmall"
                              >
                                <i className="icon-DeleteNew" />
                              </Tooltip>
                            </a>
                          </li>
                          <li className="toggleRow">
                            <Tooltip
                              content={
                                !item.nStatus
                                  ? "Show this club sponsor on mobile?"
                                  : "Hide this club sponsor on mobile?"
                              }
                              placement="bottom"
                              className="tooltipBig"
                            >
                              <input
                                type="checkbox"
                                checked={item.nStatus}
                                id={"toggle_" + item._id}
                                className="checkbox"
                                onChange={(e) =>
                                  this.setState({
                                    isStatusModal: true,
                                    deleteModalVisible: !deleteModalVisible,
                                    oSponsorId: item._id,
                                    isChecked: e.target.checked,
                                    sStatusText: !item.nStatus
                                      ? "Are you sure you want to enable"
                                      : "Are you sure you want to disable",
                                    sStatusTitle: !item.nStatus
                                      ? "Enable"
                                      : "Disable",
                                  })
                                }
                              />
                              <label
                                htmlFor={"toggle_" + item._id}
                                className="switch"
                              ></label>
                            </Tooltip>
                          </li>
                        </div>
                      );
                    })}
                </ul>
                {users.length === 0 && <Nodata centerTxt="No  Data" />}
              </Scrollbars>
              <div className="paginationSec">
                {users.length !== 0 && (
                  <CustomPagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={pageRangeDisplayed}
                    handlePageChange={this.onPageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          showModal={sponsersModalVisible}
          onHide={() =>
            this.setState({
              sponsersModalVisible: !sponsersModalVisible,
              editDetail: {},
            })
          }
          editDetail={editDetail}
          titleName={
            Object.keys(editDetail).length === 0
              ? "Add Club Sponsor"
              : "Edit Club Sponsor"
          }
          modalType="sponsersModal"
          sponsorExistsError={this.state.sponsorExistsError}
          onSubmit={(details, notificationData) => {
            this.onSubmitClick(details, notificationData);
          }}
          deleteImage={(imageType) => {
            this.onDeleteImage(imageType);
          }}
        />
        <CustomModal
          showModal={deleteModalVisible}
          _id={oSponsorId}
          isStatusModal={isStatusModal}
          titleName={!isStatusModal ? "Delete" : sStatusTitle}
          onHide={() =>
            this.setState({
              deleteModalVisible: !deleteModalVisible,
              oSponsorId: "",
            })
          }
          onClick={(id) => {
            !isStatusModal
              ? this.onDeleteUser(id)
              : this.onChangeStatus(isChecked, id);
          }}
          modalType="deleteModal"
          deletItemName="this club sponsor?"
          statusText={sStatusText}
          statusTitle={sStatusTitle}
        />
        <Footer
          divClassname={
            this.context.isDrawerOpen ? "simpleFooter" : "footersidePadding"
          }
        />
      </div>
    );
  }
}

export default ClubSponsersListing;
