import React from "react";
import "./contactuserslisting.scss";
import Constant from "../../util/constant";
import SearchBox from "../../components/SearchBox/SearchBox";
import addContactIcon from "../../assets/images/addContactIcon.svg";
import { Scrollbars } from "react-custom-scrollbars";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CustomMenuBar from "../../components/CustomMenuBar/CustomMenuBar";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import CustomModal from "../../components/Modal/Modal";
import WebService from "../../util/webService";
import CustomPagination from "../../components/Pagination/Pagination";
import MainContext from "../../MainContext";
import Tooltip from "react-simple-tooltip";
import Nodata from "../../components/Nodata/Nodata";
import { CSVLink } from "react-csv";

class ContactUsersListing extends React.Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.onSearch = this.onSearch.bind(this);
    this.state = {
      toast: {
        show: false,
        type: "Sucess",
        msg: "The club has been edited successfully.",
        errorToast: false,
      },
      users: [],
      searchText: "",
      NoData: "",
      typingTimeout: 0,
      exportList: [],
      togglepageVisible: true,
      ContactUserModalVisible: false,
      deleteModalVisible: false,
      selectedColor: "yellow",
      sClubIdentifier: this.props.location.state?.sClubIdentifier
        ? this.props.location.state?.sClubIdentifier
        : "grenta-fc",
      activePage: 1,
      itemsCountPerPage: 5,
      totalItemsCount: 0,
      pageRangeDisplayed: 7,
      nPageNo: 1,
      oUserId: "",
      defaultForm: {},
      isLoading: false,
      sortUpper: false,
      errorField: "",
      userExistsError: "",
      sortBy: { sFullName: 1 },
      start: 0,
      end: 0,
      isStatusModal: false,
      isChecked: false,
      sStatusTitle: "",
      sStatusText: "",
      sClubName: this.props.location.state?.sClubName,
      sClubLogo: this.props.location.state?.sClubLogo
        ? this.props.location.state?.sClubLogo
        : "https://res.cloudinary.com/zudu/image/upload/v1600413958/Community-Club/club-logo/mw307bdoyvlfqth82g5n.png",
    };
  }

  onApply(toggleVisible) {
    this.setState({ togglepageVisible: toggleVisible });
  }
  onSearch = (e) => {
    const self = this;
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }
    this.setState({
      searchText: e.target.value,
      typingTimeout: setTimeout(function () {
        self.getList();
      }, 500),
    });
  };
  onDeleteUser = async (id) => {
    const { updateLoading } = this.context;
    updateLoading(true);
    this.setState({
      deleteModalVisible: false,
    });
    let param = {
      iContactId: id,
      sClubIdentifier: this.state.sClubIdentifier,
    };
    await WebService.postWebServices(
      Constant.API.CONTACT_DELETE,
      param,
      (response) => {
        if (response.status >= 200 || response.status <= 210) {
          if (response.data.status === true) {
            this.setState({
              oUserId: "",
              toast: {
                show: true,
                type: "Success",
                msg: response.data.msg,
                errorToast: false,
              },
            });
            //If last record of the current page has been deleted, then set the page number to previous page.
            var start =
              this.state.activePage * this.state.itemsCountPerPage -
              (this.state.itemsCountPerPage - 1);
            var end = Math.min(
              start + this.state.itemsCountPerPage - 1,
              this.state.totalItemsCount
            );
            if (start === end && this.state.activePage !== 1) {
              this.setState({ nPageNo: this.state.activePage - 1 }, () => {
                this.getList();
              });
            } else {
              this.getList();
            }
          }
        } else {
          this.setState({
            toast: {
              show: true,
              type: "Failed",
              msg: response.data.msg,
              errorToast: true,
            },
          });
        }
      }
    );
    updateLoading(false);
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  onChangeStatus = async (checked, id) => {
    await WebService.postWebServices(
      Constant.API.CONTACT_STATUS,
      {
        iContactId: id,
        status: checked,
        sClubIdentifier: this.state.sClubIdentifier,
      },
      (response) => {
        if (
          response.status ||
          response.status >= 200 ||
          response.status <= 210
        ) {
          if (response.data.status === true) {
            this.setState({
              oUserId: "",
              deleteModalVisible: false,
              toast: {
                show: true,
                type: "Success",
                msg: response.data.msg,
                errorToast: false,
              },
            });
            this.getList();
          }
        }
      }
    );
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  onPageChange = (nPageNo) => {
    this.setState({ nPageNo: nPageNo }, () => {
      this.getList();
    });
  };
  componentWillMount() {
    this.getList();
  }
  getList = async () => {
    const { updateLoading } = this.context;
    updateLoading(true);
    let param = {
      sClubIdentifier: this.state.sClubIdentifier,
      nPageNo: this.state.nPageNo,
      nResultsPerPage: this.state.itemsCountPerPage,
      searchText: this.state.searchText,
      sSortBy:
        typeof this.state.sortBy === "undefined"
          ? "undefined"
          : JSON.stringify({
            Key: Object.keys(this.state.sortBy)[0],
            Value: this.state.sortBy[Object.keys(this.state.sortBy)[0]],
          }),
    };
    await WebService.getWebServices(
      Constant.API.CONTACT_LIST,
      param,
      (response) => {
        updateLoading(false);
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data.users;
          let totalPages = response.data.data.totalPages;
          if (response.data.status === true) {
            var start =
              this.state.nPageNo * this.state.itemsCountPerPage -
              (this.state.itemsCountPerPage - 1);
            mainData = mainData.map((item) => {
              item.id = start;
              start++;
              return item;
            });
            this.setState(
              {
                users: mainData,
                totalItemsCount: response.data.data.totalUsers,
                activePage: this.state.nPageNo,
                pageRangeDisplayed:
                  totalPages < this.state.pageRangeDisplayed
                    ? totalPages
                    : this.state.pageRangeDisplayed,
                totalPages: totalPages,
              },
              () => { }
            );
          } else {
            this.setState({
              users: [],
              NoData: mainData.msg,
            });
            this.setState({
              toast: {
                show: true,
                type: "Failed",
                msg: response.data.msg,
                errorToast: true,
              },
            });
          }
        }
      }
    );
    await WebService.getWebServices(
      Constant.API.CONTACT_EXPORT_LIST,
      param,
      (response) => {
        updateLoading(false);
        if (response.status >= 200 || response.status <= 210) {
          let mainData = response.data.data.users;
          if (response.data.status === true) {
            var exportData = [];
            mainData.map((d) => {
              exportData.push({
                "Full Name": d.sFullName,
                "Email ID": d.sEmailId,
                "Phone Number": `+${d.sCountryCode} ${d.sPhoneNumber}`,
                "Position": d.sPosition,

              });
            });
            this.setState(
              {
                exportList: exportData,
              },
              () => { }
            );
          } else {
            this.setState({
              exportList: [],
              NoData: mainData.msg,
            });
          }
        }
      }
    );
  };
  onSubmitClick = async (values) => {
    const { updateButtonLoading } = this.context;
    this.setState({ defaultForm: values });
    updateButtonLoading(true);
    var formData = new FormData();
    var apiUrl = Constant.API.CONTACT_ADD;
    if (this.state.oUserId !== "") {
      formData.append("iContactId", this.state.oUserId);
      apiUrl = Constant.API.CONTACT_EDIT;
    }

    if (values.sProfilePic !== "") {
      formData.append("sProfilePic", values.sProfilePic);
    }

    formData.append("sClubIdentifier", this.state.sClubIdentifier);
    formData.append("sFullName", values.sFullName);
    formData.append("sEmailId", values.sEmailId);
    formData.append("sCountryCode", values.sCountryCode);
    formData.append("sPhoneNumber", values.sPhoneNumber);
    formData.append("sPosition", values.sPosition);


    // formData.append("sClubLogo", this.state.sClubLogo);
    await WebService.postWebServices(apiUrl, formData, (response) => {
      if (response.status || response.status >= 200 || response.status <= 210) {
        if (response.data.status === true) {
          this.setState({
            ContactUserModalVisible: false,
            oUserId: "",
            defaultForm: {},
            toast: {
              show: true,
              type: "Success",
              msg: response.data.msg,
              errorToast: false,
            },
          });
          this.getList();
        } else {
          this.setState({
            errorField: response.data.errorField,
            userExistsError: response.data.msg,
          });
        }
      }
    });
    updateButtonLoading(false);
    setTimeout(() => {
      this.setState({
        toast: {
          show: false,
          type: this.state.toast.type,
          msg: this.state.toast.msg,
          errorToast: this.state.toast.errorToast,
        },
      });
    }, 5000);
  };
  onClear = () => {
    this.setState(
      {
        searchText: "",
        nPageNo: 1,
      },
      () => {
        setTimeout(() => {
          this.getList();
        }, 500);
      }
    );
  };
  render() {
    const {
      toast,
      ContactUserModalVisible,
      deleteModalVisible,
      users,
      totalItemsCount,
      pageRangeDisplayed,
      oUserId,
      searchText,
      sortBy,
      isChecked,
      isStatusModal,
      sStatusTitle,
      sStatusText,
      defaultForm
    } = this.state;
    return (
      <div className="ContactUsersListingPage">
        <Header {...this.props} logonotVisible={true} />
        <CustomMenuBar
          onToggleClick={(toggleVisible) => this.onApply(toggleVisible)}
          {...this.props}
        />
        <div className="centerSec">
          <ToastComponent
            onClose={() => {
              this.setState({
                toast: {
                  show: false,
                  type: this.state.toast.type,
                  msg: this.state.toast.msg,
                  errorToast: this.state.toast.errorToast,
                },
              });
            }}
            show={toast.show}
            toastMessage={toast.msg}
            toastMessageTye={toast.type}
            errorToast={toast.errorToast}
          />
          <div
            className={
              this.context.isDrawerOpen ? "rightMain" : "rightMain sidePadding"
            }
          >
            <div className="searchSec">
              <div className="leftSec">
                <span className="blackdarkTxt">List of Contact Us Users</span>
                <span className="divider"></span>
                <span className="greyTxt">{users.length} Total</span>
                <div className="searchMain">
                  <SearchBox
                    placeholder="Search name/email"
                    onChange={this.onSearch}
                    value={searchText}
                    onClear={this.onClear}
                  />
                </div>
              </div>
              <div className="rightBtn">
                <a
                  className="cyanBtn"
                  onClick={() =>
                    this.setState({
                      ContactUserModalVisible: !ContactUserModalVisible,
                      defaultForm: {}
                    })
                  }
                >
                  <img src={addContactIcon} alt="" />
                  ADD CONTACT US USER
                </a>
              </div>
            </div>
            <div className="ContactUsersListing">
              <div className="listingTopSec">
                <div className="txtSec">
                  <span className="blacTxt">Contact Us User Management</span>
                  <span className="greyTxt">
                    World Cup halftime center-half yellow card defender
                  </span>
                </div>
                <div className="btnSec">
                  {this.state.exportList.length > 0 && (
                    <div className="btnSec">
                      <CSVLink
                        data={this.state.exportList}
                        filename={"Contact Us List.csv"}
                        className="yellowExportBtn"
                      // target="_blank"
                      >
                        <i className="icon-DownloadNew clubIcon" />
                        Export
                      </CSVLink>
                    </div>
                  )}
                </div>
              </div>
              <div className="ContactUsersHeader">
                <ul>
                  <li className="nameHead">
                    <a
                      onClick={() => {
                        if (totalItemsCount > 0) {
                          if (
                            sortBy &&
                            sortBy.sFullName &&
                            sortBy.sFullName === -1
                          ) {
                            this.setState(
                              {
                                sortBy: { sFullName: 1 },
                              },
                              () => {
                                this.getList();
                              }
                            );
                          } else {
                            this.setState(
                              {
                                sortBy: { sFullName: -1 },
                              },
                              () => {
                                this.getList();
                              }
                            );
                          }
                        }
                      }}
                    >
                      NAME{" "}
                      {totalItemsCount > 0 && (
                        <i
                          className={
                            sortBy["sFullName"] === 1
                              ? "icon-long-arrow upArrow"
                              : "icon-long-arrow downArrow"
                          }
                        />
                      )}
                    </a>
                  </li>
                  {/* add class "upArrow" together with icon-long-arrow when u do sorting */}
                  <li className="mailHead">E-MAIL ADDRESS</li>
                  <li className="phoneHead">PHONE NUMBER</li>
                  {/* <li className="phoneHead">POSITION</li> */}

                  <li className="actionsHead">ACTIONS</li>
                  <li className="toggleHead">SHOW/HIDE ON MOBILE</li>
                </ul>
              </div>
              {totalItemsCount > 0 && (
                <span className="sortDiv">
                  Sort by:{" "}
                  <a
                    className="linkSmall"
                    onClick={() => {
                      if (
                        sortBy &&
                        sortBy.sFullName &&
                        sortBy.sFullName === -1
                      ) {
                        this.setState(
                          {
                            sortBy: { sFullName: 1 },
                          },
                          () => {
                            this.getList();
                          }
                        );
                      } else {
                        this.setState(
                          {
                            sortBy: { sFullName: -1 },
                          },
                          () => {
                            this.getList();
                          }
                        );
                      }
                    }}
                  >
                    Name{" "}
                    <span className="greySmall">
                      {sortBy["sFullName"] === 1 ? "(A to Z)" : "(Z to A)"}
                    </span>
                    <i
                      className={
                        sortBy["sFullName"] === 1
                          ? "icon-long-arrow upArrow"
                          : "icon-long-arrow downArrow"
                      }
                    />
                  </a>
                </span>
              )}
              {/* add class "upArrow" together with icon-lon-arrow when u do sorting */}
              <Scrollbars className="ContactUsersScroll">
                {}
                {users.length > 0 &&
                  users.map((item, index) => {
                    return (
                      <ul key={item._id}>
                        <div className="ContactUsersRow">
                          <li className="nameImgRow">
                            {/* add class "upArrow" together with nameImgRow when u do sorting */}
                            <div className="imgMain">
                              <img
                                src={
                                  item.sProfilePic && item.sProfilePic !== ""
                                    ? item.sProfilePic.replace("upload/","upload/w_50,c_scale/")
                                    : this.state.sClubLogo.replace("upload/","upload/w_50,c_scale/")
                                }
                                className="useIMG"
                                alt=""
                              />
                            </div>
                            <div className="infoSec">
                              <span className="blueTxt">{item.sFullName}</span>
                            </div>
                          </li>
                          <li className="mailRow">
                            <span className="blueTxt">{item.sEmailId ? item.sEmailId : "-"}</span>
                          </li>
                          <li className="phoneRow">
                            <span className="blueTxt">{(item.sCountryCode && item.sPhoneNumber) ? `+${item.sCountryCode} ${item.sPhoneNumber}` : '-'}</span>
                          </li>
                          {/* <li className="phoneRow">
                            <span className="blueTxt">{item.sPosition ? item.sPosition : "-"}</span>
                          </li> */}
                          <li className="actionsRow">
                            <a
                              className="clickBtn"
                              onClick={() =>{
                                console.log(item,'PROFILE PIC');
                                this.setState({
                                  ContactUserModalVisible: !ContactUserModalVisible,
                                  oUserId: item._id,
                                  defaultForm: {
                                    sFullName: item.sFullName,
                                    sEmailId: item.sEmailId,
                                    sPhoneNumber: item.sPhoneNumber,
                                    sCountryCode: item.sCountryCode,
                                    sProfilePic: item.sProfilePic && item.sProfilePic!=="" ? item.sProfilePic.replace("upload/","upload/w_120,c_scale/") : "",
                                    sPosition: item.sPosition
                                  },
                                })}
                              }
                            >
                              <Tooltip
                                content="EDIT"
                                placement="right"
                                className="tooltipSmall"
                              >
                                <i className="icon-edit_pencil" />
                              </Tooltip>
                            </a>
                            <a
                              className="clickBtn"
                              onClick={() =>
                                this.setState({
                                  deleteModalVisible: !deleteModalVisible,
                                  oUserId: item._id,
                                  isStatusModal: false,
                                })
                              }
                            >
                              <Tooltip
                                content="DELETE"
                                placement="right"
                                className="tooltipSmall"
                              >
                                <i className="icon-DeleteNew" />
                              </Tooltip>
                            </a>
                          </li>
                          <li className="toggleRow">
                            <Tooltip
                              content={
                                !item.nStatus
                                  ? "Show this contact us user on mobile?"
                                  : "Hide this contact us user on mobile?"
                              }
                              placement="bottom"
                              className="tooltipBig"
                            >
                              <input
                                type="checkbox"
                                checked={item.nStatus}
                                id={"toggle_" + item._id}
                                className="checkbox"
                                onChange={(e) =>
                                  this.setState({
                                    isStatusModal: true,
                                    deleteModalVisible: !deleteModalVisible,
                                    oUserId: item._id,
                                    isChecked: e.target.checked,
                                    sStatusText: !item.nStatus
                                      ? "Are you sure you want to enable"
                                      : "Are you sure you want to disable",
                                    sStatusTitle: !item.nStatus
                                      ? "Enable"
                                      : "Disable",
                                  })
                                }
                              />
                              <label
                                htmlFor={"toggle_" + item._id}
                                className="switch"
                              ></label>
                            </Tooltip>
                          </li>
                        </div>
                      </ul>
                    );
                  })}
                {users.length === 0 && <Nodata centerTxt="No Data" />}
                {/* <Nodata centerTxt="No Data" /> */}
              </Scrollbars>
              <div className="paginationSec">
                {/* only hide <CustomPagination/> tag (not whole this div ) */}
                {users.length !== 0 && (
                  <CustomPagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={pageRangeDisplayed}
                    handlePageChange={this.onPageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          showModal={ContactUserModalVisible}
          userExistsError={this.state.userExistsError}
          errorField={this.state.errorField}
          onHide={() =>
            this.setState({
              ContactUserModalVisible: !ContactUserModalVisible,
              oUserId: "",
              userExistsError: "",
            })
          }
          titleName={
            oUserId === "" ? "Add New Contact Us User" : "Edit Contact Us User"
          }
          modalType="contactModal"
          oUserId={oUserId}
          defaultForm={defaultForm}
          sProfilePic={defaultForm?.sProfilePic}
          onSubmit={(details) => {
            this.onSubmitClick(details);
          }}
        />
        <CustomModal
          // showModal={deleteModalVisible}
          // onHide={() =>
          //   this.setState({
          //     deleteModalVisible: !deleteModalVisible,
          //     oUserId: "",
          //   })
          // }
          // onClick={(id) => {
          //   this.onDeleteUser(id);
          // }}
          // titleName="Delete"
          // modalType="deleteModal"
          // deletItemName="contact us user?"
          showModal={deleteModalVisible}
          _id={oUserId}
          onHide={() =>
            this.setState({
              deleteModalVisible: !deleteModalVisible,
              oUserId: "",
            })
          }
          isStatusModal={isStatusModal}
          titleName={!isStatusModal ? "Delete" : sStatusTitle}
          modalType="deleteModal"
          deletItemName="contact us user?"
          onClick={(id) => {
            !isStatusModal
              ? this.onDeleteUser(id)
              : this.onChangeStatus(isChecked, id);
          }}
          statusText={sStatusText}
          statusTitle={sStatusTitle}
        />
        <Footer
          divClassname={
            this.context.isDrawerOpen ? "simpleFooter" : "footersidePadding"
          }
        />
      </div>
    );
  }
}

export default ContactUsersListing;
