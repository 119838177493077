import React, { useState, useEffect } from "react";
import './AppLink.scss';
// import txtLogo from '../../assets/images/damnTxt.png'
// import logo from '../../assets/images/logo_yellow.png'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import WebService from './../../util/webService';
import Constant from './../../util/constant';
import googleappstore from '../../assets/images/Google_Play_Store.png'
import appstore from '../../assets/images/App_Store.png'
const logo = "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png"

const AppLink = (props) => {
    let club_name;
    let ios_url;
    let android_url;
    const [share, setShare] = useState("");
    const [data, setData] = useState({});
    useEffect(() => {
        if (isBrowser === true) {
            let postId = props.location.search.replace("?newsDetails=", "");
            let eventId = props.location.search.replace("?eventDetails=", "")

            const checkStringPostId = props.location.search.match(/newsDetails/);
            const checkStringEventId = props.location.search.match(/eventDetails/);
            if (checkStringPostId || checkStringEventId) {
                setShare("share")
                WebService.getWebServices(
                    Constant.API.POSET_DETAILS,
                    { iPostId: checkStringPostId ? postId : eventId },
                    (response) => {
                        let posttitle = checkStringPostId ? postId : eventId
                        let clubName = props.match.params.clubidentifier
                        window.location.href = `https://communityclub-all-news.herokuapp.com/${clubName}?newsDetails=${posttitle}`
                        const project = () => {
                            switch (props.match.params.clubidentifier) {

                                case "grenta-fc": return { club_name: "Gretna FC", ios_url: "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224", android_url: "https://play.google.com/store/apps/details?id=com.cc.gretnafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1620981198/Community-Club/club-logo/mgcuafvm1slt6ripo31w.png" };
                                //adding for gretna and grenta

                                case "gretna-fc": return { club_name: "Gretna FC", ios_url: "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224", android_url: "https://play.google.com/store/apps/details?id=com.cc.gretnafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1620981198/Community-Club/club-logo/mgcuafvm1slt6ripo31w.png" };
                                case "greystone-rovers": return { club_name: "Greystone Rovers", ios_url: "https://apps.apple.com/us/app/greystone-rovers/id1574600729", android_url: "https://play.google.com/store/apps/details?id=com.cc.greystone", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1625126402/Community-Club/club-logo/lzpevglzzwtidartpepz.png" };
                                case "threave-roversfc": return { club_name: "Threave RoversFC", ios_url: "https://apps.apple.com/us/app/threave-rovers-fc/id1582757107", android_url: "https://play.google.com/store/apps/details?id=com.cc.threaveroversfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1629373051/Community-Club/club-logo/Threave-Rovers-Logo_qmf1q4.png" };
                                case "newtongrange-star": return { club_name: "Newtongrange Star", ios_url: "https://apps.apple.com/us/app/newtongrange-star/id1586115189", android_url: "https://play.google.com/store/apps/details?id=com.cc.newtongrange", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1631534852/Community-Club/club-logo/patrmbv7o8urczrldctn.png" };
                                case "spartans-fc-youth": return { club_name: "Spartans FC Youth", ios_url: "https://apps.apple.com/us/app/spartans-fc-youth/id1593153042", android_url: "https://play.google.com/store/apps/details?id=com.cc.spartansfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1635740678/Community-Club/club-logo/nox8etx0nvbhcu4nuqdb.png" };
                                case "lochryan-fc": return { club_name: "Lochryan Football Club", ios_url: "https://apps.apple.com/us/app/lochryan-boys-club/id1597892114", android_url: "https://play.google.com/store/apps/details?id=com.cc.lochryanboys", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1651650852/Community-Club/club-logo/amw1zoku7yuliihboea9.png" };
                                case "hutchison-vale-fc": return { club_name: "Hutchison Vale FC", ios_url: "https://apps.apple.com/us/app/hutchison-vale-fc/id1603393507", android_url: "https://play.google.com/store/apps/details?id=com.cc.hutchisonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1639560393/Community-Club/club-logo/sg6od07mppsh1lg3qaae.png" };
                                case "haddington-athletic-fc": return { club_name: "Haddington Athletic FC", ios_url: "https://apps.apple.com/us/app/haddington-athletic/id1608340052", android_url: "https://play.google.com/store/apps/details?id=com.cc.haddingtonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1642045731/Community-Club/club-logo/khw7jam8vfqkponrmagb.png" };
                                case "northbank-fc": return { club_name: "Northbank FC", ios_url: "https://apps.apple.com/us/app/northbank-fc/id1610298458", android_url: "https://play.google.com/store/apps/details?id=com.cc.northbankfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1644895124/Community-Club/club-logo/q82hgm7gihycgyrfdmtb.png" };
                                case "tranent-fc": return { club_name: "TranentColts FC", ios_url: "https://apps.apple.com/us/app/tranent-colts-fc/id1625682293", android_url: "https://play.google.com/store/apps/details?id=com.cc.tranentcoltsfc", logo: "https://res.cloudinary.com/zudu/image/upload/v1709720465/Community-Club/club-logo/tranentcolts-fc.png" };
                                case "cleator-moor-celtic-fc": return { club_name: "Cleator Moor Celtic FC", ios_url: 'https://apps.apple.com/us/app/cleator-moor-celtic-fc/id1631798430', android_url: 'https://play.google.com/store/apps/details?id=com.cc.cleatormoorfc', logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1655367934/Community-Club/club-logo/nxl4ul4f6dezfvibdcxp.png" };

                                case "workington-afc": return {
                                    club_name: "Workington AFC",
                                    ios_url: "https://apps.apple.com/us/app/workington-afc/id1625663209",
                                    android_url: "https://play.google.com/store/apps/details?id=com.cc.workingtonafc",
                                    logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/adpmhkls9tungxnppwi2.png"
                                };

                                case "bonnyrigg-rose-fc": return {
                                    club_name: "Bonnyrigg Rose FC",
                                    ios_url: 'https://apps.apple.com/us/app/bonnyrigg-rose-community-fc/id1642887126',
                                    android_url: 'https://play.google.com/store/apps/details?id=com.cc.bonnyriggrose',
                                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999950/Community-Club/club-logo/bonnyrigg-rose-fc.png"
                                }

                                case "penrith-afc": return {
                                    club_name: "Penrith AFC",
                                    ios_url: 'https://apps.apple.com/us/app/penrith-afc/id1639993183',
                                    android_url: 'https://play.google.com/store/apps/details?id=com.cc.penrithafc',
                                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999914/Community-Club/club-logo/penrith-afc.png"
                                }

                                case "east-kilbride-utd": return {
                                    club_name: "East Kilbride Utd",
                                    ios_url: "https://apps.apple.com/us/app/itunes-connect/6444095997",
                                    android_url: "https://play.google.com/store/apps/details?id=com.cc.eastkilbride",
                                    logo: "https://res.cloudinary.com/zudu/image/upload/v1697451960/Community-Club/club-logo/east-kilbride-utd.png"
                                };

                                case "sandbach-united-fc": return {
                                    club_name: "Sandbach United FC",
                                    ios_url: "https://apps.apple.com/gb/app/sandbach-united-fc/id6463419526",
                                    android_url: "https://play.google.com/store/apps/details?id=com.cc.sandbachunitedfc",
                                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705998445/Community-Club/club-logo/sandbach-united-fc.png"
                                };

                                case "keswick-fc": return {
                                    club_name: "Keswick FC",
                                    ios_url: "https://apps.apple.com/us/app/keswick-fc/id6444525862",
                                    android_url: "https://play.google.com/store/apps/details?id=com.cc.keswickfc",
                                    logo: "https://res.cloudinary.com/zudu/image/upload/v1697451862/Community-Club/club-logo/keswick-fc.jpg"
                                };

                                case "giffnock-sc": return {
                                    club_name: "Giffnock Sc",
                                    ios_url: "https://apps.apple.com/us/app/giffnock-sc/id6448082835",
                                    android_url: "https://play.google.com/store/apps/details?id=com.cc.giffnocksc",
                                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999582/Community-Club/club-logo/giffnock-sc.png"
                                };

                                case "blackburn-eagles-fc": return {
                                    club_name: "Blackburn Eagles FC",
                                    ios_url: "https://apps.apple.com/us/app/blackburn-eagles-fc/id1671515956",
                                    android_url: "https://play.google.com/store/apps/details?id=com.cc.blackburneaglesfc&pli=1",
                                    logo: "https://res.cloudinary.com/zudu/image/upload/v1705999827/Community-Club/club-logo/blackburn-eagles-fc.png"
                                };

                                default: return { club_name: "", ios_url: "", android_url: "", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png" }
                            }
                        }
                        setData(project())
                    }
                );

            } else {
                setShare("app")
                const project = () => {
                    switch (props.match.params.clubidentifier) {

                        case "grenta-fc": return { club_name: "Gretna FC", ios_url: "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224", android_url: "https://play.google.com/store/apps/details?id=com.cc.gretnafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1620981198/Community-Club/club-logo/mgcuafvm1slt6ripo31w.png" };
                        //adding for gretna and grenta
                        case "gretna-fc": return { club_name: "Gretna FC", ios_url: "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224", android_url: "https://play.google.com/store/apps/details?id=com.cc.gretnafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1620981198/Community-Club/club-logo/mgcuafvm1slt6ripo31w.png" };
                        case "greystone-rovers": return { club_name: "Greystone Rovers", ios_url: "https://apps.apple.com/us/app/greystone-rovers/id1574600729", android_url: "https://play.google.com/store/apps/details?id=com.cc.greystone", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1625126402/Community-Club/club-logo/lzpevglzzwtidartpepz.png" };
                        case "threave-roversfc": return { club_name: "Threave RoversFC", ios_url: "https://apps.apple.com/us/app/threave-rovers-fc/id1582757107", android_url: "https://play.google.com/store/apps/details?id=com.cc.threaveroversfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1629373051/Community-Club/club-logo/Threave-Rovers-Logo_qmf1q4.png" };
                        case "newtongrange-star": return { club_name: "Newtongrange Star", ios_url: "https://apps.apple.com/us/app/newtongrange-star/id1586115189", android_url: "https://play.google.com/store/apps/details?id=com.cc.newtongrange", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1631534852/Community-Club/club-logo/patrmbv7o8urczrldctn.png" };
                        case "spartans-fc-youth": return { club_name: "Spartans FC Youth", ios_url: "https://apps.apple.com/us/app/spartans-fc-youth/id1593153042", android_url: "https://play.google.com/store/apps/details?id=com.cc.spartansfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1635740678/Community-Club/club-logo/nox8etx0nvbhcu4nuqdb.png" };
                        case "lochryan-fc": return { club_name: "Lochryan Football Club", ios_url: "https://apps.apple.com/us/app/lochryan-boys-club/id1597892114", android_url: "https://play.google.com/store/apps/details?id=com.cc.lochryanboys", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1651650852/Community-Club/club-logo/amw1zoku7yuliihboea9.png" };
                        case "hutchison-vale-fc": return { club_name: "Hutchison Vale FC", ios_url: "https://apps.apple.com/us/app/hutchison-vale-fc/id1603393507", android_url: "https://play.google.com/store/apps/details?id=com.cc.hutchisonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1639560393/Community-Club/club-logo/sg6od07mppsh1lg3qaae.png" };
                        case "haddington-athletic-fc": return { club_name: "Haddington Athletic FC", ios_url: "https://apps.apple.com/us/app/haddington-athletic/id1608340052", android_url: "https://play.google.com/store/apps/details?id=com.cc.haddingtonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1642045731/Community-Club/club-logo/khw7jam8vfqkponrmagb.png" };
                        case "northbank-fc": return { club_name: "Northbank FC", ios_url: "https://apps.apple.com/us/app/northbank-fc/id1610298458", android_url: "https://play.google.com/store/apps/details?id=com.cc.northbankfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1644895124/Community-Club/club-logo/q82hgm7gihycgyrfdmtb.png" };
                        case "tranent-fc": return { club_name: "TranentColts FC", ios_url: "https://apps.apple.com/us/app/tranent-colts-fc/id1625682293", android_url: "https://play.google.com/store/apps/details?id=com.cc.tranentcoltsfc", logo: "https://res.cloudinary.com/zudu/image/upload/v1709720465/Community-Club/club-logo/tranentcolts-fc.png" };
                        case "cleator-moor-celtic-fc": return { club_name: "Cleator Moor Celtic FC", ios_url: 'https://apps.apple.com/us/app/cleator-moor-celtic-fc/id1631798430', android_url: 'https://play.google.com/store/apps/details?id=com.cc.cleatormoorfc', logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1655367934/Community-Club/club-logo/nxl4ul4f6dezfvibdcxp.png" };

                        case "workington-afc": return {
                            club_name: "Workington AFC",
                            ios_url: "https://apps.apple.com/us/app/workington-afc/id1625663209",
                            android_url: "https://play.google.com/store/apps/details?id=com.cc.workingtonafc",
                            logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/adpmhkls9tungxnppwi2.png"
                        };

                        case "bonnyrigg-rose-fc": return {
                            club_name: "Bonnyrigg Rose FC",
                            ios_url: 'https://apps.apple.com/us/app/bonnyrigg-rose-community-fc/id1642887126',
                            android_url: 'https://play.google.com/store/apps/details?id=com.cc.bonnyriggrose',
                            logo: "https://res.cloudinary.com/zudu/image/upload/v1705999950/Community-Club/club-logo/bonnyrigg-rose-fc.png"
                        }

                        case "penrith-afc": return {
                            club_name: "Penrith AFC",
                            ios_url: 'https://apps.apple.com/us/app/penrith-afc/id1639993183',
                            android_url: 'https://play.google.com/store/apps/details?id=com.cc.penrithafc',
                            logo: "https://res.cloudinary.com/zudu/image/upload/v1705999914/Community-Club/club-logo/penrith-afc.png"
                        }

                        case "east-kilbride-utd": return {
                            club_name: "East Kilbride Utd",
                            ios_url: "https://apps.apple.com/us/app/itunes-connect/6444095997",
                            android_url: "https://play.google.com/store/apps/details?id=com.cc.eastkilbride",
                            logo: "https://res.cloudinary.com/zudu/image/upload/v1697451960/Community-Club/club-logo/east-kilbride-utd.png"
                        };

                        case "sandbach-united-fc": return {
                            club_name: "Sandbach United FC",
                            ios_url: "https://apps.apple.com/gb/app/sandbach-united-fc/id6463419526",
                            android_url: "https://play.google.com/store/apps/details?id=com.cc.sandbachunitedfc",
                            logo: "https://res.cloudinary.com/zudu/image/upload/v1705998445/Community-Club/club-logo/sandbach-united-fc.png"
                        };

                        case "keswick-fc": return {
                            club_name: "Keswick FC",
                            ios_url: "https://apps.apple.com/us/app/keswick-fc/id6444525862",
                            android_url: "https://play.google.com/store/apps/details?id=com.cc.keswickfc",
                            logo: "https://res.cloudinary.com/zudu/image/upload/v1697451862/Community-Club/club-logo/keswick-fc.jpg"
                        };

                        case "giffnock-sc": return {
                            club_name: "Giffnock Sc",
                            ios_url: "https://apps.apple.com/us/app/giffnock-sc/id6448082835",
                            android_url: "https://play.google.com/store/apps/details?id=com.cc.giffnocksc",
                            logo: "https://res.cloudinary.com/zudu/image/upload/v1705999582/Community-Club/club-logo/giffnock-sc.png"
                        };

                        case "blackburn-eagles-fc": return {
                            club_name: "Blackburn Eagles FC",
                            ios_url: "https://apps.apple.com/us/app/blackburn-eagles-fc/id1671515956",
                            android_url: "https://play.google.com/store/apps/details?id=com.cc.blackburneaglesfc&pli=1",
                            logo: "https://res.cloudinary.com/zudu/image/upload/v1705999827/Community-Club/club-logo/blackburn-eagles-fc.png"
                        };

                        default: return { club_name: "", ios_url: "", android_url: "", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png" }
                    }
                }
                setData(project())
            }

        } else {
            setShare("app")
            const project = () => {
                switch (props.match.params.clubidentifier) {

                    case "grenta-fc": return { club_name: "Gretna FC", ios_url: "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224", android_url: "https://play.google.com/store/apps/details?id=com.cc.gretnafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1620981198/Community-Club/club-logo/mgcuafvm1slt6ripo31w.png" };
                    //adding for gretna and grenta
                    case "gretna-fc": return { club_name: "Gretna FC", ios_url: "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224", android_url: "https://play.google.com/store/apps/details?id=com.cc.gretnafc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1620981198/Community-Club/club-logo/mgcuafvm1slt6ripo31w.png" };
                    case "greystone-rovers": return { club_name: "Greystone Rovers", ios_url: "https://apps.apple.com/us/app/greystone-rovers/id1574600729", android_url: "https://play.google.com/store/apps/details?id=com.cc.greystone", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1625126402/Community-Club/club-logo/lzpevglzzwtidartpepz.png" };
                    case "threave-roversfc": return { club_name: "Threave RoversFC", ios_url: "https://apps.apple.com/us/app/threave-roversfc/id1582757107", android_url: "https://play.google.com/store/apps/details?id=com.cc.threaveroversfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1629373051/Community-Club/club-logo/Threave-Rovers-Logo_qmf1q4.png" };
                    case "newtongrange-star": return { club_name: "Newtongrange Star", ios_url: "https://apps.apple.com/us/app/newtongrange-star/id1586115189", android_url: "https://play.google.com/store/apps/details?id=com.cc.newtongrange", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1631534852/Community-Club/club-logo/patrmbv7o8urczrldctn.png" };
                    case "spartans-fc-youth": return { club_name: "Spartans FC Youth", ios_url: "https://apps.apple.com/us/app/spartans-fc-youth/id1593153042", android_url: "https://play.google.com/store/apps/details?id=com.cc.spartansfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1635740678/Community-Club/club-logo/nox8etx0nvbhcu4nuqdb.png" };
                    case "lochryan-fc": return { club_name: "Lochryan Football Club", ios_url: "https://apps.apple.com/us/app/lochryan-boys-club/id1597892114", android_url: "https://play.google.com/store/apps/details?id=com.cc.lochryanboys", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1651650852/Community-Club/club-logo/amw1zoku7yuliihboea9.png" };
                    case "hutchison-vale-fc": return { club_name: "Hutchison Vale FC", ios_url: "https://apps.apple.com/us/app/hutchison-vale-fc/id1603393507", android_url: "https://play.google.com/store/apps/details?id=com.cc.hutchisonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1639560393/Community-Club/club-logo/sg6od07mppsh1lg3qaae.png" };
                    case "haddington-athletic-fc": return { club_name: "Haddington Athletic FC", ios_url: "https://apps.apple.com/us/app/haddington-athletic/id1608340052", android_url: "https://play.google.com/store/apps/details?id=com.cc.haddingtonfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1642045731/Community-Club/club-logo/khw7jam8vfqkponrmagb.png" };
                    case "northbank-fc": return { club_name: "Northbank FC", ios_url: "https://apps.apple.com/us/app/northbank-fc/id1610298458", android_url: "https://play.google.com/store/apps/details?id=com.cc.northbankfc", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1644895124/Community-Club/club-logo/q82hgm7gihycgyrfdmtb.png" };
                    case "tranent-fc": return { club_name: "TranentColts FC", ios_url: "https://apps.apple.com/us/app/tranent-colts-fc/id1625682293", android_url: "https://play.google.com/store/apps/details?id=com.cc.tranentcoltsfc", logo: "https://res.cloudinary.com/zudu/image/upload/v1709720465/Community-Club/club-logo/tranentcolts-fc.png" };
                    case "cleator-moor-celtic-fc": return { club_name: "Cleator Moor Celtic FC", ios_url: 'https://apps.apple.com/us/app/cleator-moor-celtic-fc/id1631798430', android_url: 'https://play.google.com/store/apps/details?id=com.cc.cleatormoorfc', logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1655367934/Community-Club/club-logo/nxl4ul4f6dezfvibdcxp.png" };

                    case "workington-afc": return {
                        club_name: "Workington AFC",
                        ios_url: "https://apps.apple.com/us/app/workington-afc/id1625663209",
                        android_url: "https://play.google.com/store/apps/details?id=com.cc.workingtonafc",
                        logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/Community-Club/club-logo/adpmhkls9tungxnppwi2.png"
                    };

                    case "bonnyrigg-rose-fc": return {
                        club_name: "Bonnyrigg Rose FC",
                        ios_url: 'https://apps.apple.com/us/app/bonnyrigg-rose-community-fc/id1642887126',
                        android_url: 'https://play.google.com/store/apps/details?id=com.cc.bonnyriggrose',
                        logo: "https://res.cloudinary.com/zudu/image/upload/v1705999950/Community-Club/club-logo/bonnyrigg-rose-fc.png"
                    }

                    case "penrith-afc": return {
                        club_name: "Penrith AFC",
                        ios_url: 'https://apps.apple.com/us/app/penrith-afc/id1639993183',
                        android_url: 'https://play.google.com/store/apps/details?id=com.cc.penrithafc',
                        logo: "https://res.cloudinary.com/zudu/image/upload/v1705999914/Community-Club/club-logo/penrith-afc.png"
                    }

                    case "east-kilbride-utd": return {
                        club_name: "East Kilbride Utd",
                        ios_url: "https://apps.apple.com/us/app/itunes-connect/6444095997",
                        android_url: "https://play.google.com/store/apps/details?id=com.cc.eastkilbride",
                        logo: "https://res.cloudinary.com/zudu/image/upload/v1697451960/Community-Club/club-logo/east-kilbride-utd.png"
                    };

                    case "sandbach-united-fc": return {
                        club_name: "Sandbach United FC",
                        ios_url: "https://apps.apple.com/gb/app/sandbach-united-fc/id6463419526",
                        android_url: "https://play.google.com/store/apps/details?id=com.cc.sandbachunitedfc",
                        logo: "https://res.cloudinary.com/zudu/image/upload/v1705998445/Community-Club/club-logo/sandbach-united-fc.png"
                    };

                    case "keswick-fc": return {
                        club_name: "Keswick FC",
                        ios_url: "https://apps.apple.com/us/app/keswick-fc/id6444525862",
                        android_url: "https://play.google.com/store/apps/details?id=com.cc.keswickfc",
                        logo: "https://res.cloudinary.com/zudu/image/upload/v1697451862/Community-Club/club-logo/keswick-fc.jpg"
                    };

                    case "giffnock-sc": return {
                        club_name: "Giffnock Sc",
                        ios_url: "https://apps.apple.com/us/app/giffnock-sc/id6448082835",
                        android_url: "https://play.google.com/store/apps/details?id=com.cc.giffnocksc",
                        logo: "https://res.cloudinary.com/zudu/image/upload/v1705999582/Community-Club/club-logo/giffnock-sc.png"
                    };

                    case "blackburn-eagles-fc": return {
                        club_name: "Blackburn Eagles FC",
                        ios_url: "https://apps.apple.com/us/app/blackburn-eagles-fc/id1671515956",
                        android_url: "https://play.google.com/store/apps/details?id=com.cc.blackburneaglesfc&pli=1",
                        logo: "https://res.cloudinary.com/zudu/image/upload/v1705999827/Community-Club/club-logo/blackburn-eagles-fc.png"
                    };

                    default: return { club_name: "", ios_url: "", android_url: "", logo: "https://res.cloudinary.com/dtzkljk1x/image/upload/v1611649515/Community-Club/Logo_Black_kpqcvk.png" }
                }
            }
            setData(project())
        }
    }, [])

    return (
        <div className='nodataFound'>
            <div className="backYellowarea">
            </div>
            {share === "app" && <><div className="upperLogo">
                <div className="mainImg">
                    <img src={data?.logo} alt="txtlogo" fluid="true" />
                </div>
            </div>

                <div className="centerPart">
                    <p className='boldText'>This is an article/event, which you can view on our mobile app. <br />To view this please download the app on an Android or iOS device.</p>
                    <div className="imgSec">
                        < a href={data.android_url}><img src={googleappstore} alt="smallJogging" fluid="true" /></a>
                        < a href={data.ios_url}><img src={appstore} alt="smallJogging" fluid="true" /></a>
                    </div>
                </div></>}
            {share === "share" && <><div className="upperLogo">
                <div className="mainImg">
                    <img src={data?.logo} alt="txtlogo" fluid="true" />
                </div>
            </div><div className="centerPart">
                    <p className='boldText'>Redirecting.....</p>
                </div></>}


        </div>
    )
}



export default AppLink;