import React, { Component } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Login from "./pages/Login/login";
import ForgotPassword from "./pages/ForgotPassword/forgotpassword";
import ResetPassword from "./pages/ResetPassword/resetpassword";
import FirstTimeLogin from "./pages/FirstTimeLogin/firsttimelogin";
import Dashboard from "./pages/Dashboard/dashboard";
import clubslisting from "./pages/ClubsListing/clubslisting";
import userslisting from "./pages/UsersListing/userslisting";
import viewclub from "./pages/ViewClub/viewclub";
import MainContext from "./MainContext";
import fixturestableslisting from "./pages/FixturesTablesListing/fixturestableslisting";
import clubsponserslisting from "./pages/ClubSponsersListing/clubsponserslisting";
import settingsList from "./pages/SettingsList/SettingsList";
import contactuserslisting from "./pages/ContactUsersListing/contactuserslisting";
import viewprofile from "./pages/ViewProfile/viewprofile";
import Constant from "./util/constant";
import ResetSuccessPage from "./pages/ResetSuccessPage/ResetSuccessPage";
import NotFound from "./pages/NotFound/NotFound";
import AppLink from "./pages/AppLink/AppLink";
import ShareAppLink from "./pages/ShareAppLink/ShareAppLink";
import Share from "./pages/Share/Share";
import { isIOS, isAndroid } from "react-device-detect";
// var deeplink = require('node-deeplink');
class App extends Component {
  constructor(props) {
    super(props);
    var withoutParam = window.location.href.indexOf("share") !== -1;
    // if (withoutParam) {
    //   var now = new Date().valueOf();
    //   setTimeout(function () {
    //     if (new Date().valueOf() - now > 100) return;
    //     if (isIOS) {
    //       window.location = "https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224";
    //     } else if (isAndroid) {
    //       window.location = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.cc.gretnafc";
    //     }
    //   }, 25);

    //   let typeId = window.location.href.split("?");
    //   let id = typeId[1].split("=");
    //   window.location = "communityclub://" + id[0] + "/" + id[1];
    // }
    // var withoutParam = window.location.href.indexOf("share") !== -1;
    // if (withoutParam) {
    //     let typeId = window.location.href.split("?");
    //     let id = typeId[1].split("=");
    //     // alert(encodeURI(Constant.WEB_URL+"?url=communityclub://" + id[0] + "/" + id[1]));
    //     deeplink({
    //       url:encodeURI(Constant.WEB_URL+"?url=communityclub://" + id[0] + "/" + id[1]),
    //       fallback: encodeURI(Constant.WEB_URL+"?url=communityclub://" + id[0] + "/" + id[1]),
    //       android_package_name: 'com.cc.gretnafc',
    //       ios_store_link:
    //         'https://apps.apple.com/us/app/gretna-fc-2008-official-app/id1547784224'
    //     });
    //     alert('YESSSS')
    // }
    this.state = {
      isDrawerOpen: false,
      isLoading: false,
      isButtonLoading: false,
      clubAdded: false,
      contextToast: {
        show: false,
        type: "Success",
        msg: "",
        errorToast: false,
      },
    };
  }
  baseRoute = {
    path: "/",
    component: Login,
  };
  loggedInUserBaseRoute = "/Dashboard";
  routes = [
    {
      path: "/",
      component: Login,
      exact: true,
      authenticate: true,
    },
    {
      path: "/login",
      component: Login,
      exact: true,
      authenticate: true,
    },
    {
      path: "/forgotpassword",
      component: ForgotPassword,
      exact: true,
      authenticate: true,
    },
    {
      path: "/resetpassword/:token/:clubidentifier?",
      component: ResetPassword,
      exact: true,
      authenticate: true,
    },
    {
      path: "/firsttimelogin/:token",
      component: FirstTimeLogin,
      exact: true,
      authenticate: true,
    },

    {
      path: "/Dashboard",
      component: Dashboard,
      exact: true,
      authenticate: true,
    },
    {
      path: "/clubs",
      component: clubslisting,
      exact: true,
      authenticate: true,
    },
    {
      path: "/users",
      component: userslisting,
      exact: true,
      authenticate: true,
    },
    {
      path: "/club",
      component: viewclub,
      exact: true,
      authenticate: true,
    },
    {
      path: "/club/fixtures",
      component: fixturestableslisting,
      exact: true,
      authenticate: true,
    },
    {
      path: "/club/sponsors",
      component: clubsponserslisting,
      exact: true,
      authenticate: true,
    },
    // {
    //   path: "/clubsponserslisting",
    //   component: clubsponserslisting,
    //   exact: true,
    //   authenticate: true
    // },
    {
      path: "/homepagelisting",
      component: settingsList,
      exact: true,
      authenticate: true,
    },
    {
      path: "/club/contacts",
      component: contactuserslisting,
      exact: true,
      authenticate: true,
    },
    {
      path: "/viewprofile",
      component: viewprofile,
      exact: true,
      authenticate: true,
    },
    {
      path: "/resetsuccess/:id",
      component: ResetSuccessPage,
      exact: true,
      authenticate: false,
    },
    {
      path: "/notfound",
      component: NotFound,
      exact: true,
      authenticate: false,
    },
    {
      path: "/share",
      component: Share,
      exact: true,
      authenticate: false,
    },
    {
      path: "/applink/:clubidentifier",
      component: AppLink,
      exact: true,
      authenticate: false,
    },
    {
      path: "/:clubidentifier",
      component: ShareAppLink,
      exact: true,
      authenticate: false,
    },
  ];
  updateDrawer = (value) => {
    this.setState({ isDrawerOpen: value });
  };
  updateLoading = (value) => {
    this.setState({ isLoading: value });
  };
  updateButtonLoading = (value) => {
    this.setState({ isButtonLoading: value });
  };
  updateToast = (show, type, msg, errorToast) => {
    this.setState(
      {
        contextToast: {
          show: show,
          type: type,
          msg: msg,
          errorToast: errorToast,
        },
      },
      () => {
        setTimeout(() => {
          this.setState({
            contextToast: {
              show: false,
              type: type,
              msg: msg,
              errorToast: errorToast,
            },
          });
        }, 5000);
      }
    );
  };
  isLoggedIn() {
    let user_id = localStorage.getItem("KeyUser");
    if (!user_id) return false;
    else return true;
  }
  authenticateRoute(props) {
    let { path } = props.match;
    // let user = JSON.parse(localStorage.getItem('KeyUser'))
    let isLoggedIn = this.isLoggedIn();
    let routeIndex = this.routes.findIndex((route) => route.path === path);
    if (
      (path === "/" ||
        path === "/login" ||
        path.includes("/forgotpassword") ||
        path.includes("/firsttimelogin") ||
        path.includes("/resetpassword") ||
        path.includes("/firsttimelogin")) &&
      isLoggedIn
    ) {
      return <Redirect to={this.loggedInUserBaseRoute} />;
    } else if (
      !isLoggedIn &&
      routeIndex !== -1 &&
      (path.includes("/forgotpassword") ||
        path.includes("/firsttimelogin") ||
        path.includes("/resetpassword") ||
        path.includes("/firsttimelogin"))
    ) {
      const Component = this.routes[routeIndex].component;
      return <Component {...props} />;
    } else if (path !== "/" && routeIndex !== -1) {
      //if user tries to access inner pages of the club module from outside of club module then redirect them to the clubs listing page
      if (
        (path.includes("/club") ||
          path.includes("/club/fixtures") ||
          path.includes("/club/sponsors")) &&
        !this.props
      ) {
        return <Redirect to="/clubs" />;
      } else {
        const Component = this.routes[routeIndex].component;
        return isLoggedIn ? <Component {...props} /> : <Redirect to="/" />;
      }
    } else {
      const Component = this.baseRoute.component;
      return <Component {...props} />;
    }
  }
  render() {
    return (
      <div className="App">
        <MainContext.Provider
          value={{
            isDrawerOpen: this.state.isDrawerOpen,
            updateDrawer: this.updateDrawer,
            isLoading: this.state.isLoading,
            updateLoading: this.updateLoading,
            isButtonLoading: this.state.isButtonLoading,
            updateButtonLoading: this.updateButtonLoading,
            contextToast: this.state.contextToast,
            updateToast: this.updateToast,
          }}
        >
          <BrowserRouter>
            <Switch>
              {this.routes.map((route, index) => {
                return route.authenticate ? (
                  <Route
                    key={index}
                    exact={route.exact}
                    path={route.path}
                    render={this.authenticateRoute.bind(this)}
                  />
                ) : (
                  <Route
                    key={index}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                  />
                );
              })}
              <Route path="*" component={NotFound} />
            </Switch>
          </BrowserRouter>
        </MainContext.Provider>
      </div>
    );
  }
}
export default App;
